import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.communicationServiceApiBaseUrl}contacts`;
export default {

  async fetchAllContacts(query) {
    const url = `${URL}${query}`;
    return (await httpClient.get(url)).data;
  },
};
