import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const url = environment.apiBaseURL + 'configuration';
export default {
  async getAll(query) {
    return (await httpClient.get(`${url}${query}`)).data.data;
  },
  async create(data) {
    return httpClient.post(url, data);
  },
  async update(data) {
    data = {...data, '_method': 'PUT'};
    return httpClient.put(`${url}/${data.id}`, data);
  },
  async delete(id) {
    return httpClient.delete(`${url}/${id}`);
  },
  async updateConfig(config, id) {
    return (await httpClient.post(`${url}/${id}/value?_method=PUT`, config)).data;
  },
  getConfigByKey(key) {
    const URL = url + `/findByKey/${key}`;
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  }
};
