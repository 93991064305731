const sharedRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import('./shared-views/Login')
  },
  {
    path: "/",
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: "/sliders",
        component: () => import('@/app/shared/shared-views/Slider'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول السلايدرات", active: true}
          ],
          pageTitle: "سلايدرات"
        }
      },
      {
        path: "/tags/:key",
        component: () => import('./shared-views/TagsMain'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول التاغات", active: true}
          ],
          pageTitle: "التاغات"
        }
      },
      {
        path: "/analytics/:key",
        component: () => import('./shared-views/AnalyticsMain'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول الإحصائيات", active: true}
          ],
          pageTitle: "الإحصائيات"
        }
      },
      {
        path: "/users/:key",
        component: () => import('./shared-views/DashboardUsersMain'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول المستخدمين", active: true}
          ],
          pageTitle: "المستخدمون"
        }
      },
      {
        path: "/ecommerce/users",
        component: () => import('./shared-views/EcommerceUsersMain'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول حسابات الزبائن", active: true}
          ],
          pageTitle: "حسابات الزبائن"
        }
      },
      {
        path: "/configuration/:key",
        component: () => import('./shared-views/Configuration'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "الإعدادات", active: true}
          ],
          pageTitle: "إعدادات عامة"
        }
      },
      {
        path: "/dev-configuration",
        component: () => import('@/app/shared/shared-views/configuration/ConfigurationCrudView.vue'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "اعدادات المطور", active: true}
          ],
          pageTitle: "إعدادات المطور"
        }
      },
      {
        path: "/roles",
        component: () => import('./shared-views/RolesMain'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول الأدوار", active: true}
          ],
          pageTitle: "الأدوار"
        }
      },
      {
        path: '/roles/:id/permissions',
        component: () => import('./shared-views/Permissions'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "الأدوار", url: '/roles'},
            {title: "صلاحيات الدور", active: true}
          ],
          pageTitle: 'صلاحيات الدور'
        }
      },
      {
        path: '/users/:id/roles',
        component: () => import('./shared-views/UserRoles'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول المستخدمين", url: "/users"},
            {title: "أدوار المستخدم", active: true}
          ],
          pageTitle: 'أدوار المستخدم'
        }
      },
      {
        path: '/users/:id/permissions',
        component: () => import('./shared-views/UserPermissions'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "جدول المستخدمين", url: "/users"},
            {title: "صلاحيات المستخدم", active: true}
          ],
          pageTitle: 'صلاحيات المستخدم'
        }
      },
      {
        path: '/groups',
        component: () => import('./shared-views/GroupsMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "المجموعات", active: true}
          ],
          pageTitle: 'المجموعات'
        }
      },
      {
        path: '/groups/:id/members',
        component: () => import('./shared-views/GroupMembersMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "المجموعات", url: '/groups'},
            {title: "أعضاء المجموعة", active: true}
          ],
          pageTitle: 'أعضاء المجموعة'
        }
      },
      {
        path: '/districts',
        component: () => import('./shared-views/DistrictsMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "المناطق", active: true}
          ],
          pageTitle: 'المناطق'
        }
      },
      {
        path: '/jobs/failed',
        component: () => import('./shared-views/FailedJobs'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "الرسائل المعلقة", active: true}
          ],
          pageTitle: 'الرسائل المعلقة'
        }
      },
      {
        path: '/telegram/contacts',
        component: () => import('./shared-views/TelegramContacts'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: "جهات اتصال التلغرام", active: true}
          ],
          pageTitle: "جهات اتصال التلغرام"
        }
      },
    ]
  }
];

export default sharedRoutes;
