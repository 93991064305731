<template>
  <div>
    <ag-table
      :form-table-check="true"
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitDeleteMultipleRecords="deleteVoluntaryForm($event, null , true)"
      @emitShowDetails="showDetails(0)"
    >
      <template v-slot:dropdown>
        <checked-card
          :btn-name="btnName"
          :grid-options="gridOptions"
          :is-voluntary-form="true"
          :show="true"
          :status="status"
          @fetchAllForms="fetchAllForms(($event))"
        />
      </template>


      <template v-slot:checkButton>
        <checked-card
          :btn-name="btnName"
          :grid-options="gridOptions"
          :is-voluntary-form="true"
          :show="false"
          :status="status"
          @fetchAllForms="fetchAllForms(($event))"
        />
      </template>
    </ag-table>


    <vs-popup
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
      title="تفاصيل الإستمارة"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>الاسم :</label>
          <vs-input
            v-model="form.fullname"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الجنس :</label>
          <vs-input
            v-model="form.gender"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            v-model="form.address"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الهاتف :</label>
          <vs-input
            v-model="form.phone"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            v-model="form.mobile"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>القيد :</label>
          <vs-input
            v-model="form.kayd"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>مكان الولادة :</label>
          <vs-input
            v-model="form.birthplace"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>تاريخ الولادة :</label>
          <vs-input
            v-model="form.birthday"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الجنسية :</label>
          <vs-input
            v-model="form.nationality"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div class="col-span-3">
          <label>رابط حساب الفيسبوك :</label>
          <vs-input
            v-model="form.facebook_profile"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
            style="direction:ltr;"
          />
        </div>
      </div>


      <div class="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6 px-4 mt-6">
        <div>
          <label>المرحلة الدراسية :</label>
          <vs-input
            v-model="form.education_level"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>المدرسة :</label>
          <vs-input
            v-model="form.school"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>الجامعة :</label>
          <vs-input
            v-model="form.university"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>السنة الدراسية :</label>
          <vs-input
            v-model="form.class"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>
      </div>


      <div class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 px-4 mt-6">
        <div>
          <label>عدد ساعات التطوع :</label>
          <vs-input
            v-model="form.hours"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>الأيام :</label>
          <vs-input
            v-model="form.days"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">المهارات الأخرى :</label>

        <div
          v-for="(item,index) in form.other_skills"
          :key="index"
          :class="[index == form.other_skills.length-1 ? '':'borderBottom pb-8']"
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
        >
          <div>
            <label>المهارات :</label>


            <vs-input
              v-model="item.skill "
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder="الوقت"
            />
          </div>

          <div>
            <label>الوصف :</label>


            <vs-input
              v-model="item.desc"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder="الوقت"
            />
          </div>
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">مجالات التطوع :</label>


        <div>
          <vs-input
            v-model="form.field"
            class="w-full mt-6"
            color="#5E445A"
            disabled
            name="title"
            placeholder="الوقت"
          />
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">أعمال التطوع السابقة :</label>

        <div
          v-if="!form.previous_volunteer_works"
          class="grid grid-cols-1 mt-6"
        >
          <vs-input
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
            value="لا يوجد"
          />
        </div>

        <div
          v-for="(item,index) in form.previous_volunteer_works"
          v-else
          :key="index"
          :class="[index == form.previous_volunteer_works.length-1 ? '':'borderBottom pb-8']"
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
        >
          <div>
            <label>المكان :</label>


            <vs-input
              v-model="item.place"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder=""
            />
          </div>

          <div>
            <label>نوع العمل :</label>


            <vs-input
              v-model="item.work"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder=""
            />
          </div>
        </div>
      </div>


      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          type="filled"
          ycolor="success"
          @click="popupActiveDetails=false"
        >
          حسناً
        </vs-button>
      </div>

      <!-- <div class="mt-6 px-4">
                        <label >ملاحظات الزبون :</label>
                        <vs-textarea placeholder="الملاحظات" v-model="order.user_note" disabled  class="w-full mt-3" color="#5E445A"/>
                </div> -->
    </vs-popup>


    <vs-popup
      :active.sync="popupActive"
      :button-close-hidden="true"
      title="تفاصيل الإستمارة"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>الاسم :</label>
          <vs-input
            v-model="form.fullname"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الجنس :</label>
          <vs-input
            v-model="form.gender"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            v-model="form.address"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الهاتف :</label>
          <vs-input
            v-model="form.phone"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            v-model="form.mobile"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>القيد :</label>
          <vs-input
            v-model="form.kayd"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>مكان الولادة :</label>
          <vs-input
            v-model="form.birthplace"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>تاريخ الولادة :</label>
          <vs-input
            v-model="form.birthday"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div>
          <label>الجنسية :</label>
          <vs-input
            v-model="form.nationality"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>

        <div class="col-span-3">
          <label>رابط حساب الفيسبوك :</label>
          <vs-input
            v-model="form.facebook_profile"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
            style="direction:ltr;"
          />
        </div>
      </div>


      <div class="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6 px-4 mt-6">
        <div>
          <label>المرحلة الدراسية :</label>
          <vs-input
            v-model="form.education_level"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>المدرسة :</label>
          <vs-input
            v-model="form.school"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>الجامعة :</label>
          <vs-input
            v-model="form.university"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>السنة الدراسية :</label>
          <vs-input
            v-model="form.class"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>
      </div>


      <div class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 px-4 mt-6">
        <div>
          <label>عدد ساعات التطوع :</label>
          <vs-input
            v-model="form.hours"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>


        <div>
          <label>الأيام :</label>
          <vs-input
            v-model="form.days"
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
          />
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">المهارات الأخرى :</label>

        <div
          v-for="(item,index) in form.other_skills"
          :key="index"
          :class="[index == form.other_skills.length-1 ? '':'borderBottom pb-8']"
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
        >
          <div>
            <label>المهارات :</label>


            <vs-input
              v-model="item.skill "
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder="الوقت"
            />
          </div>

          <div>
            <label>الوصف :</label>


            <vs-input
              v-model="item.desc"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder="الوقت"
            />
          </div>
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">مجالات التطوع :</label>


        <div>
          <vs-input
            v-model="form.field"
            class="w-full mt-6"
            color="#5E445A"
            disabled
            name="title"
            placeholder="الوقت"
          />
        </div>
      </div>


      <div class="mt-6 px-4">
        <label class="sectionName">أعمال التطوع السابقة :</label>

        <div
          v-if="!form.previous_volunteer_works"
          class="grid grid-cols-1 mt-6"
        >
          <vs-input
            class="w-full mt-3"
            color="#5E445A"
            disabled
            name="title"
            placeholder=""
            value="لا يوجد"
          />
        </div>

        <div
          v-for="(item,index) in form.previous_volunteer_works"
          v-else
          :key="index"
          :class="[index == form.previous_volunteer_works.length-1 ? '':'borderBottom pb-8']"
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
        >
          <div>
            <label>المكان :</label>


            <vs-input
              v-model="item.place"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder=""
            />
          </div>

          <div>
            <label>نوع العمل :</label>


            <vs-input
              v-model="item.work"
              class="w-full mt-3"
              color="#5E445A"
              disabled
              name="title"
              placeholder=""
            />
          </div>
        </div>
      </div>


      <div class="flex justify-center items-center mt-8 px-4 w-full">
        <vs-button
          v-show="record.isPrevious"
          class="mx-4"
          type="filled"
          ycolor="success"
          @click="showDetails(record.index,'previous')"
        >
          السابق
        </vs-button>

        <vs-button
          v-show="record.isNext"
          class="mx-4"
          type="filled"
          ycolor="success"
          @click="showDetails(record.index,'next')"
        >
          التالي
        </vs-button>
      </div>

      <!-- <div class="mt-6 px-4">
                        <label >ملاحظات الزبون :</label>
                        <vs-textarea placeholder="الملاحظات" v-model="order.user_note" disabled  class="w-full mt-3" color="#5E445A"/>
                </div> -->
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import utilities from "../../../shared/utilities";
import checkedCard from "../../website-components/checkedFormsCard";
import AgSwitch from "@/app/shared/shared-components/ag-grid/AgTableSwitchCell";

const formsRepo = RepositoryFactory.get('forms');
export default {
  name: "VolunteeringForm",
  components: {
    AgTable,
    checkedCard
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      record: Object,
      form: Object,
      btnName: '',
      status: '',
    };
  },
  methods: {

    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'fullname',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'تاريح الولادة',
          field: 'birthday',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'مكان الولادة',
          field: 'birthplace',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الجنس',
          field: 'gender',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الجنسية',
          field: 'nationality',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'القيد',
          field: 'kayd',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الأرضي',
          field: 'phone',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الموبايل',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رابط حساب الفيسبوك',
          field: 'facebook_profile',
          width: 250,
          filter: true,
        },
        {
          headerName: 'العنوان',
          field: 'address',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المرحلة الدراسية',
          field: 'education_level',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المدرسة',
          field: 'school',
          filter: 'agTextColumnFilter',
        }, {
          headerName: 'الجامعة',
          field: 'university',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'السنة الدراسية',
          field: 'class',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'أيام التطوع',
          field: 'days',
          filter: 'agTextColumnFilter',
          valueGetter: daysGetter
        },
        {
          headerName: 'ساعات التطوع',
          field: 'hours',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'مجالات التطوع',
          field: 'field',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المهارات الأخرى',
          field: 'other_skills',
          filter: 'agTextColumnFilter',
          valueGetter: otherSkillsGetter
        },
        {
          headerName: 'اعمال تطوعية سابقة',
          field: 'previous_volunteer_works',
          filter: 'agTextColumnFilter',
          valueGetter: previousVolunteerWorksGetter
        },
        {
          headerName: 'تاريخ الإنشاء',
          field: 'created_at',
          valueFormatter: self.dateFormatter,
          filter: 'agTextColumnFilter',
          sortable: false,
          width: 250
        },
        {
          headerName: 'تغيير حالة المقابلة',
          field: 'checked',
          valueGetter:
            function getObject(params) {
              return params;
            },
          filter: false,
          cellRendererFramework: AgSwitch,
          cellRendererParams: {
            switchAction: function (valueGetter) {
              self.checkedForm(valueGetter.id);
            }
          },
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            deleteRecord: function (id, rowIndex) {
              self.deleteVoluntaryForm(id, rowIndex);
            },
            actions: ['view', 'delete']
          },
        },
      ];
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.form = record;
    },
    deleteVoluntaryForm(ids, index, isMultiple = false) {
      if (isMultiple)
        ids = ids.join(',');
      formsRepo.deleteVoluntaryForm(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple) {
            this.fetchAllForms();
          } else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
      });
    },
    showDetails(index, action) {
      this.popupActive = true;
      this.record = utilities.fetchRecordIndex(this.rowData.length, index, action);
      this.form = this.rowData[this.record.index];
    },
    confirmCheckedMultipleRecords() {
      this.isMultiple = true;
      if (!this.gridOptions.api.getSelectedRows().length)
        return;
      let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
      let title = this.status ? ' هل أنت متأكد من عدم مقابلة ' + recordsIds.length + '  من الاستمارت ؟ ' : ' هل أنت متأكد من مقابلة ' + recordsIds.length + ' من الاستمارت ؟ ';
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: title,
        text: `في حال الموافقة, سيتم تغيير حالة الاستمارات `,
        acceptText: 'موافق',
        accept: this.checkedForm,
        parameters: [recordsIds]
      });
    },
    fetchAllForms(isChecked = false) {
      this.btnName = `${isChecked ? 'لم تتم المقابلة' : 'تمت المقابلة'}`;
      this.status = isChecked;
      formsRepo.fetchVoluntaryForms(isChecked).then((data) => {
        this.rowData = data;
      });
    },
    checkedForm(ids, isMultiple = false) {
      formsRepo.checkedForm(ids, this.status, isMultiple, true).then((response) => {
        if (response.code === 200) {
          if (this.status)
            this.fetchAllForms(true);
          else
            this.fetchAllForms();
        }
      });
    },
    dateFormatter(params) {
      return utilities.dateFormatter(params.data.created_at);
    },
  },

  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllForms();
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
window.previousVolunteerWorksGetter = function (params) {
  if (params.data.previous_volunteer_works) {
    return params.data.previous_volunteer_works.map(value => {
      let skill = value.work;
      skill += ',' + value.place;
      return skill;
    }).join(' / ');
  }
};
window.otherSkillsGetter = function (params) {
  if (params.data.other_skills) {
    return params.data.other_skills.map(value => {
      let skill = value.skill;
      skill += ',' + value.desc;
      return skill;
    }).join(' / ');
  }
};
window.daysGetter = function (params) {
  if (params.data.other_skills) {
    return params.data.days.map(value => {
      let skill = value;
      return skill;
    }).join(' / ');
  }
};
</script>


<style scoped>
.borderBottom {
  border-bottom: 0.5px solid #5E445A;
}
</style>
