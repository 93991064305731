import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.websiteApiBaseUrl;
export default {
  fetchAllFormsByType(formType, isChecked) {
    const query = isChecked ? '?filters[checked]=1' : '?filters[checked]=0';
    const url = `${URL}forms/types/${formType}${query}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  deleteForm(formId) {
    const url = `${URL}forms/${formId}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  deleteVoluntaryForm(formId) {
    const url = `${URL}voluntaryForms/${formId}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  checkedForm(ids, isChecked, isMultiple, isVoluntaryForms) {
    const url = isVoluntaryForms ? `${URL}voluntaryForms/checked` : `${URL}forms/checked`;
    let id = [];
    id.push(ids);
    let checkedForms = {
      checked: isChecked ? false : true,
      ids: isMultiple ? ids[0] : id
    };
    return httpClient.put(url, checkedForms)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  fetchVoluntaryForms(isChecked) {
    const query = isChecked ? '?filters[checked]=1' : '?filters[checked]=0';
    const url = `${URL}voluntaryForms${query}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  }

};
