import blogMain from './website-views/website-blog/BlogMain';
import FeedbackMain from './website-views/website-feedback/FeedbackMain';
import FaqsMain from './website-views/website-blog/FaqsMain';
import MessagesMain from './website-views/website-feedback/MessagesMain';
import ZadiMain from './website-views/website-zadi/ZadiMain';
import TrainingForm from './website-views/website-forms/TrainingForm';
import TeachingForm from './website-views/website-forms/TeachingForm';
import EmploymentForm from './website-views/website-forms/EmploymentForm';
import VolunteeringForm from './website-views/website-forms/VolunteeringForm';

const websiteRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main.vue'),
    children: [
      {
        path: 'blog/:key',
        component: blogMain,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول المدونات', active: true}
          ],
          pageTitle: 'المدونات'
        }
      },
      {
        path: 'faqs',
        component: FaqsMain,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول لأسئلة والأجوبة', active: true}
          ],
          pageTitle: 'الأسئلة والأجوبة'
        }
      },
      {
        path: 'feedback/:key',
        component: FeedbackMain,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول التوصيات', active: true}
          ],
          pageTitle: 'التوصيات'
        }
      },
      {
        path: 'website/messages',
        component: MessagesMain,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول الرسائل', active: true}
          ],
          pageTitle: 'الرسائل'
        }
      },
      {
        path: 'visitors/:key',
        component: ZadiMain,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول زوّار المبادرات', active: true}
          ],
          pageTitle: 'زوّار المبادرات'
        }
      },
      {
        path: 'training-forms',
        component: TrainingForm,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول إستمارات التدريب', active: true}
          ],
          pageTitle: 'إستمارات التدريب'
        }
      },
      {
        path: 'teaching-forms',
        component: TeachingForm,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول إستمارات التدريس', active: true}
          ],
          pageTitle: 'إستمارات التدريس'
        }
      },
      {
        path: 'volunteering-forms',
        component: VolunteeringForm,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول إستمارات التطوع', active: true}
          ],
          pageTitle: 'إستمارات التطوع'
        }
      },
      {
        path: 'employment-forms',
        component: EmploymentForm,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول إستمارات التوظيف', active: true}
          ],
          pageTitle: 'إستمارات التوظيف'
        }
      },
    ]
  }
];
export default websiteRoutes;
