import firebase from 'firebase/app';
import 'firebase/firebase-messaging';

var firebaseConfig = {
  apiKey: "AIzaSyB6AoQOv1z-5dRylySHUM2MuVKT6mc5_dg",
  authDomain: "tamayoz-core.firebaseapp.com",
  projectId: "tamayoz-core",
  storageBucket: "tamayoz-core.appspot.com",
  messagingSenderId: "865191792765",
  appId: "1:865191792765:web:01c2aa1fc921eae673a114",
  measurementId: "G-47EWE2XE7N"
};

firebase.initializeApp(firebaseConfig);
const vapidKey = 'BMbJWx-0wuVR0o2uVpwoxHUe-07EDmrreOZa6mJ7IZhd6OKBZTFILET7IMfrY42l7b90w7gmRMnyKP7mJjhDFs0';
export default firebase.messaging();
export {vapidKey};
