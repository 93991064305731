<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteBlog($event, null , true)"
    />
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="السؤال"
            placeholder="السؤال"
            type="text"
            v-model="blog.title"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="الجواب"
            placeholder="الجواب"
            type="text"
            v-model="blog.short_desc"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-select
            :options="tags"
            :reduce="tag => tag.id"
            class="w-full"
            label="name"
            name="التاغ"
            v-model="blog.tag_id"
            validation-rules="required"
          />
        </div>
      </div>
    </tm-popup>
    <vs-popup
      :active.sync="popupActiveDetails"
      title="تفاصيل المدونة"
    >
      <form>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.id }}</h4>
            <br>
          </div>

          <div class="vx-col sm:w-1/3 w-full">
            <h4>السؤال:</h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.title }}</h4>
            <br>
          </div>
        </div>
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الجواب: </h4>
            <br>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.short_desc }}</h4>
          </div>
        </div>
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>التاغ : </h4>
            <br>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.tag_name }}</h4>
          </div>
        </div>
        <div class="vx-row mt-12">
          <div class="vx-col sm:w-1/4 mb-4">
            <vs-button
              @click="popupActiveDetails=false"
              type="filled"
              ycolor="success"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>
<script>

import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import utilities from '../../../shared/utilities/index';
import TmInput from "../../../shared/shared-components/TmInput";
import TmSelect from "../../../shared/shared-components/TmSelect";
import TmPopup from "../../../shared/shared-components/TmPopup";

const blogRepo = RepositoryFactory.get('blog');
const tagRepo = RepositoryFactory.get('tag');

export default {
  name: "FaqsMain",
  components: {
    AgTable,
    TmSelect,
    TmInput,
    TmPopup
  },

  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActiveDetails: false,
      dialogTitle: String,
      blog: {
        id: null,
        title: '',
        short_desc: '',
        type: '',
        tag_id: ''
      },
      tags: [],
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          headerName: 'السؤال',
          field: 'title',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الجواب',
          field: 'short_desc',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'التاغ',
          field: 'tag_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteBlog(id, rowIndex);
            },
            editRecord: function (blog) {
              self.editRecord(blog);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete']
          },
        },
      ];
    },

    deleteBlog(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      blogRepo.deleteBlog(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllBlogs();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      });
    },

    createRecord() {
      utilities.initObjectMembers(this.blog);
      this.dialogTitle = 'اضافة مدونة';
      this.blog.type = 'FAQ';
      this.openPopup();
    },

    editRecord(blog) {
      this.dialogTitle = 'تعديل مدونة';
      Object.assign(this.blog, blog);
      this.blog.tag_id = blog.tag !== null ? blog.tag.id : null;
      this.openPopup();
    },

    openPopup() {
      this.$refs.popup.open();
    },
    onSubmit() {
      this.isLoading = true;
      if (this.blog.id !== null) {
        this.updateBlog(this.blog);
      } else {
        this.storeBlog(this.blog);
      }
    },

    storeBlog(blog) {
      blogRepo.storeBlog(this.$objectToFD(blog)).then((response) => {
        this.$refs.popup.endLoading();
        if (response.data.code === 201) {
          this.$refs.popup.close();
          this.rowData.unshift(response.data.data);
        }
      });
    },

    updateBlog(blog) {
      let editedBlog = {
        id: blog.id,
        title: blog.title,
        short_desc: blog.short_desc,
        type: blog.type,
        tag_id: blog.tag_id
      };
      blogRepo.updateBlog(this.$objectToFD(editedBlog), editedBlog.id).then((response) => {
        this.$refs.popup.endLoading();
        if (response.data.code === 200) {
          this.$refs.popup.close();
          let index = this.rowData.map((blog) => {
            return blog.id;
          }).indexOf(response.data.data.id);
          this.rowData[index] = response.data.data;
          this.rerender();
        }
      });
    },
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.blog = record;
    },

    fetchAllBlogs() {
      let type = 'FAQ';
      blogRepo.fetchAllBlogs(type).then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
    fetchAllTags() {
      tagRepo.fetchAllTags('website').then((response) => {
        this.tags = response;
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllTags();
    this.fetchAllBlogs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
<style scoped>

.custom-image-size {
  width: 40em;
}

</style>
