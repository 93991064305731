<template>
  <!-- <vs-col
    vs-align="center"
    vs-justify="center"
    vs-type="flex"
    vs-w="2">
    <vs-switch
      :color="getSwitchColor(params.data.checked)"
      @input="switchAction"
      icon-pack="feather"
      v-model="params.data.checked"
      vs-icon="icon-check"/>
  </vs-col> -->

  <div class="flex items-center justify-center h-full">
    <vs-switch
      :color="getSwitchColor(params.data.checked)"
      @input="switchAction"
      icon-pack="feather"
      v-model="params.data.checked"
      vs-icon="icon-check"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AgTableSwitchCell",
  methods: {
    switchAction() {
      this.params.switchAction(this.params.data);
    },
    getSwitchColor(isChecked) {
      if (isChecked)
        return 'success';
    }
  }
});
</script>

<style scoped>
.vs-switch-primary {
  background: #d94d4d;
}
</style>
