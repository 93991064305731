import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

export default {
  login(payload) {
    const url = environment.apiBaseURL + 'login';

    var input = {
      email: payload.userDetails.email,
      password: payload.userDetails.password
    };

    return httpClient.post(url, input)
      .then(res => res.data['data']);
  },
  addDeviceToken(payload) {
    const url = environment.apiBaseURL + 'device-token';
    let fcmToken = {
      device_token: payload
    };
    return httpClient.post(url, fcmToken)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  logout(payload) {
    const url = environment.apiBaseURL + 'logout';

    let fcmToken = {
      deviceToken: payload,
    };
    return httpClient.post(url, fcmToken)
      .then(res => res["data"])
      .catch(error => console.log(error));
  }
};
