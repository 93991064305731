/*=========================================================================================
File Name: store.js
Description: Vuex store
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import {sharedModule} from "../app/shared/shared-index";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    sharedModule
  },
  plugins: [
    createPersistedState({
      paths: ['sharedModule'],
    }),
  ],

});
