const quizRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '/raven_configuration',
        component: () => import('./quiz-views/RavenConfig'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'إعدادات رافن', active: true}
          ],
          pageTitle: 'رافن'
        }
      },
      {
        path: '/raven_results',
        component: () => import('./quiz-views/RavenResults'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: ' نتائج رافن', active: true}
          ],
          pageTitle: ' نتائج رافن'
        }
      }
    ]
  }
];
export default quizRoutes;
