<template>
  <div
    v-if="params.value"
    class="flex justify-center"
  >
    <img
      v-if="params.value.split('.').pop() !== 'json'"
      :src="params.value"
      class="product-img onHover"
      :width="params.width === undefined ? '100%' : params.width"
      @click="openInNewWindow(params.value)"
    >
    <v-lottie-player
      v-else
      name="scooterAnim"
      loop
      :path="params.value"
    />
  </div>
</template>

<script>
import Vue from "vue";
import VueLottiePlayer from "vue-lottie-player";

export default Vue.extend({
  name: "AgTableCellImage",
  components: {
    vLottiePlayer: VueLottiePlayer
  },
  methods: {
    openInNewWindow(image) {
      window.open(image, '_blank');
    }
  },
});
</script>


<style scoped>
.onHover:hover {
  opacity: 0.7;
  transition: 0.3s;
}
</style>

