import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.apiBaseURL}districts`;
export default {

  fetchAllDistricts(query) {
    const url = `${URL}${query}`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeDistrict(district) {
    return httpClient.post(URL, district)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateDistrict(district, districtId) {
    const url = `${URL}/${districtId}?_method=PUT`;
    return httpClient.post(url, district)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  deleteDistrict(districtId) {
    const url = `${URL}/${districtId}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  }
};
