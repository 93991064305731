import state from "./shared-state";
import mutations from "./shared-mutations";
import actions from "./shared-actions";
import getters from "./shared-getters";

export default {
  state,
  mutations,
  actions,
  getters
};
