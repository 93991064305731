export const donationsRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '/donations/programs',
        component: () => import('./views/programs/programs'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'برامج التبرع', active: true}
          ],
          pageTitle: 'برامج التبرع'
        }
      },
      {
        path: '/donations/all',
        component: () => import('./views/donations/allDonaitions'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'التبرعات', active: true}
          ],
          pageTitle: 'جدول التبرعات'
        }
      },
      {
        path: '/donations/programDetails/:id',
        component: () => import('./views/programs/programDetails'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'برامج التبرع', url: '/donations/programs'},
            {title: 'البرنامج', active: true}

          ],
          pageTitle: 'البرنامج'
        },
        children: [
          {
            path: 'campaigns',
            name: 'campaigns',
            component: () => import('./views/campaigns/campaigns.vue'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/'},
                {title: 'برامج التبرع', url: '/donations/programs'},
                {title: 'الحملات', active: true}
              ],
              pageTitle: 'الحملات'
            }
          },
          {
            path: 'emergencies',
            name: 'emergencies',
            component: () => import('./views/emergencies/emergencies'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/'},
                {title: 'برامج التبرع', url: '/donations/programs'},
                {title: 'الحالات الطارئة', active: true}
              ],
              pageTitle: 'الحالات الطارئة'
            }
          },
          {
            path: 'websiteDonations',
            name: 'websiteDonations',
            component: () => import('./views/donations/programDonations'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/'},
                {title: 'برامج التبرع', url: '/donations/programs'},
                {title: 'التبرع العام', active: true}
              ],
              pageTitle: 'التبرع العام'
            }
          },
        ]
      },
      {
        path: '/donations/campaignsDetails/:programId/:id',
        component: () => import('./views/campaigns/campaignsDetails'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'برامج التبرع', url: '/donations/programs'},
            {title: 'الحملات', name: 'campaigns'},
            {title: 'الحملة', active: true}
          ],
          pageTitle: 'الحملة'
        }
      },
      {
        path: '/donations/emergencyDetails/:programId/:id',
        component: () => import('./views/emergencies/emergencyDetails'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'برامج التبرع', url: '/donations/programs'},
            {title: 'الحالات الطارئة', name: 'emergencies'},
            {title: 'الحالة الطارئة', active: true}
          ],
          pageTitle: 'الحالة الطارئة'
        }
      },
      {
        path: '/kareem/faqs',
        component: () => import('./views/faqs/faqsMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول لأسئلة والأجوبة', active: true}
          ],
          pageTitle: 'الأسئلة والأجوبة'
        }
      },
    ]
  }
];
