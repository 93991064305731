import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.apiBaseURL + 'users';
export default {

  fetchAllUsers(projectName) {
    const url = URL + `?project=${projectName}`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  getCurrentUser() {
    const url = environment.apiBaseURL + 'me';
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeUser(user) {
    return httpClient.post(URL, user)
      .then(res => res)
      .catch(error => console.log(error));
  },

  storeUserAll(user) {
    const url = `${URL}/all`;
    return httpClient.post(url, user)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateUserAll(user) {
    const url = `${URL}/${user.id}/all?_method=PUT`;
    return httpClient.post(url, user)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  fetchUserById(userId) {
    const url = URL + `/${userId}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  updateUser(user, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, user)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteUser(userId) {
    let url = URL + '/' + userId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  updateProfileInfo(user) {
    const url = URL + `?_method=PATCH`;
    return httpClient.post(url, user)
      .then(res => res)
      .catch(error => console.log(error));
  }
};
