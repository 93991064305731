import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.apiBaseURL + 'group';
export default {

  fetchAllGroups() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  sendMessage(message) {
    const url = URL + `/${message.groupId}/messages`;
    return httpClient.post(url, {message: message.text})
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  fetchGroupById(groupId) {
    const url = URL + `/${groupId}`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  storeGroup(group) {
    return httpClient.post(URL, group)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updateGroup(group) {
    const url = URL + `/${group.id}?_method=PUT`;
    return httpClient.post(url, group)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteGroup(groupId) {
    const url = URL + `/${groupId}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  importGroupFromExcel(excelData) {
    const url = environment.apiBaseURL + `import/groups`;
    let excelFile = new FormData();
    excelFile.append('ExcelGroups', excelData);
    return httpClient.post(url, excelFile)
      .then(res => res)
      .catch(error => console.log(error));
  }
};
