<!-- =========================================================================================
    File Name: DragAndDropMultipleLists.vue
    Description: Drag and Drop to multiple lists
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card class="py-4">
    <p>اسحب وأفلت من <code>{{ list1title }}</code> إلى <code>{{ list2title }}</code></p>

    <div class="flex justify-between items-center mb-8 mt-4">
      <div>
        <vs-input
          icon-pack="feather"
          placeholder="ابحث في الصلاحيات ..."
          v-model="searchKey"
          class="is-label-placeholder"
          @keyup="search()"
        />
      </div>

      <div>
        <slot/>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 scrollOverFlow">
        <vs-list>
          <vs-list-header
            :title="list1title"
            color="primary"
          />
          <draggable
            :list="permissions"
            group="people"
            class="p-2 cursor-move"
          >
            <vs-list-item
              v-for="(listItem, index) in permissions"
              :key="index"
              :title="listItem.name_ar"
            />
          </draggable>
        </vs-list>
      </div>
      <div class="vx-col w-full md:w-1/2 scrollOverFlow">
        <vs-list>
          <vs-list-header
            :title="list2title"
            color="primary"
          />
          <draggable
            :list="list2"
            group="people"
            class="p-2 cursor-move"
          >
            <vs-list-item
              v-for="(listItem, index) in list2"
              :key="index"
              :title="listItem.name_ar"
            />
          </draggable>
        </vs-list>
      </div>
    </div>
  </vx-card>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'VxDragAndDropMultipleLists',

  data() {
    return {
      searchKey: null,
      permissions: this.list1
    };
  },
  props: {
    list1title: {type: String, required: true},
    list2title: {type: String, required: true},
    list1: {type: Array, required: true},
    list2: {type: Array, required: true},
  },
  watch: {
    list1: function (newPermissions, oldPermissions) {
      this.permissions = newPermissions;
      this.searchKey = null;
    }
  },
  components: {
    draggable,
  },
  methods: {
    search() {
      this.permissions = this.list1.filter(item => item.name_ar.includes(this.searchKey));
    }
  }
};
</script>
<style scoped>
.scrollOverFlow {
  height: 50vh;
  overflow-y: scroll;
}
</style>
