<template>
  <vs-popup :title="title" :active.sync="active" :fullscreen="fullscreen" :onclose="close">
    <vs-progress v-if="isLoading" indeterminate color="primary" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="validationObserver">
      <form @keydown.enter.prevent="() => { if (!submitButtonDisabled) handleSubmit(onSubmit) }">
        <slot />
        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button :disabled="isLoading || submitButtonDisabled" ycolor="success" type="filled"
              @click.prevent="handleSubmit(onSubmit)">
              حفظ
            </vs-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vs-popup>
</template>

<script>
export default {
  name: "TmPopup",
  props: {
    submit: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    submitButtonDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      active: false
    };
  },
  watch: {
    active: function (newValue) {
      if (!newValue) {
        this.close();
      }
    }
  },
  methods: {
    open() {
      this.active = true;
    },
    close() {
      this.endLoading();
      this.$refs.validationObserver.reset();
      this.$emit('closed');
      this.active = false;
    },
    loading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    onSubmit() {
      this.loading();
      this.submit();
    }
  },
  mounted() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        this.close();
      }
    });
  }
};
</script>

<style>
.vs-popup--header {
  background-color: #5E445A !important;
}
</style>