import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.communicationServiceApiBaseUrl}queue/jobs`;
export default {

  async fetchAllFailedJobs() {
    const url = `${URL}/failed`;
    return (await httpClient.get(url)).data;
  },
  async retrySending(jobsId,isMultiple) {
    if(isMultiple){
      const url = `${URL}/retry`;
      const ids = { ids : jobsId.map(Number)};
      return (await httpClient.post(url, ids)).data;
    }
    else {
      const url = `${URL}/${jobsId}/retry`;
      return (await httpClient.get(url)).data;
    }
  },
  async deleteFailedJobs(jobIds, isMultiple){
    const url = `${URL}/delete`;
    const ids = { ids : isMultiple ? jobIds : [ Number(jobIds)]};
    return (await httpClient.post(url, ids)).data;

  }
};
