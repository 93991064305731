import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";
import utilities from '../../shared/utilities/index';


const URL = environment.websiteApiBaseUrl + 'analytics';

export default {

  fetchAllAnalytics(type) {
    let url = `${URL}?filters[type]=${type}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeAnalytic(analytic) {
    const analyticAsformData = utilities.transformToFormData(analytic);
    return httpClient.post(URL, analyticAsformData)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updateAnalytic(analytic) {
    const analyticAsformData = utilities.transformToFormData(analytic);
    const url = URL + `/${analytic.id}?_method=PUT`;
    return httpClient.post(url, analyticAsformData)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteAnalytic(analyticId, isImage = false) {
    let url = URL + '/' + analyticId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  }
};
