import generalTypesRepository from "./generalTypesRepository";
import SliderRepository from "./sliderRepository";
import userRepository from "./userRepository";
import configRepository from "./configRepository";
import notificationRepository from "./notificationRepository";
import roleRepository from "./roleRepository";
import permissionRepository from "./permissionRepository";
import groupRepository from "./groupRepository";
import groupMembersRepository from "./groupMembersRepository";
import districtsRepository from "./districtsRepository";
import failedJobsRepository from "@/app/shared/shared-repositories/failedJobsRepository";
import telegramContactsRepository from "@/app/shared/shared-repositories/telegramContactsRepository";

export const repositories = {
  generalTypes: generalTypesRepository,
  slider: SliderRepository,
  userRepository,
  configRepository,
  notificationRepository,
  roleRepository,
  permissionRepository,
  groupRepository,
  groupMembersRepository,
  districtsRepository,
  failedJobsRepository,
  telegramContactsRepository
};

export const SharedRepositoryFactory = {
  get: name => repositories[name]
};
