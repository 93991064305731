<template>
  <div id="ag-grid-demo">
    <vx-card>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center mb-6">
        <!-- ITEMS PER PAGE -->

        <slot name="dropdown" class="flex" />

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <!-- <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"> -->
        <!-- class="nav_icon flex items-center" :class="[personalRedDotStatus == true ? 'active_red_dot':'']" -->
        <div v-if="formTableCheck == false" class="flex items-center justify-center search">
          <p class="mr-6">
            البحث
          </p>
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="بحث .." />
        </div>

        <div class="flex items-center justify-center" />

        <div class="flex items-center justify-center">
          <div v-if="formTableCheck == true" class="flex items-center justify-center search">
            <p class="mr-6">
              البحث
            </p>
            <vs-input v-model="searchQuery" @input="updateSearchQuery" placeholder="بحث .." />
            <!-- class="mb-4 md:mb-0 mr-4" -->
          </div>

          <div v-can="permissions.add">
            <button v-if="checkAgGridEvent('emitAddNewRecord')" @click="addNewRecord()"
              class="flex items-center btn_Option px-3 ml-4 add">
              <feather-icon icon="PlusIcon" class="mr-2 button_icon" />
              <span>إضافة</span>
            </button>
          </div>

          <div v-can="permissions.delete">
            <button v-if="checkAgGridEvent('emitDeleteMultipleRecords')" @click="confirmDeleteMultipleRecords()"
              class="flex items-center btn_Option px-3 ml-4 del">
              <feather-icon icon="Trash2Icon" class="mr-2 button_icon" />
              <span>حذف متعدد</span>
            </button>
          </div>

          <button @click="gridApi.exportDataAsCsv()" class="flex items-center btn_Option px-3 ml-4 export">
            <feather-icon icon="SaveIcon" class="mr-2 button_icon" />
            <span>تصدير بصيغة CSV</span>
          </button>

          <button v-if="checkAgGridEvent('emitShowDetails')" @click="showDetails()"
            class="flex items-center btn_Option ml-4 px-3 showDetails">
            <feather-icon icon="ListIcon" class="mr-2 button_icon" />
            <span>عرض التفاصيل</span>
          </button>

          <button v-if="showCompleted && checkAgGridEvent('emitShowCompletedRecords')" @click="showCompletedRecords()"
            class="flex items-center btn_Option ml-4 px-3 showCompleted">
            <feather-icon icon="KeyIcon" class="mr-2 button_icon" />
            <span>عرض الطلبات المنتهية</span>
          </button>

          <button v-if="!showCompleted" @click="showCurrentRecords()"
            class="flex items-center btn_Option ml-4 px-2 showCurrent">
            <feather-icon icon="ActivityIcon" class="mr-2 button_icon" />
            <span>عرض الطلبات الفعالة</span>
          </button>
        </div>
      </div>

      <!-- <button v-on:click="autoSizeAll(false)">Auto-Size All</button> -->
      <!-- <button v-on:click="sizeColumnsToFit()">sizeColumnsToFit</button> -->

      <!-- class="ag-theme-material ag-grid-table" -->
      <!-- style="height:800px;" -->

      <ag-grid-vue style="height: 612px;" ref="agGridTable" :grid-options="gridOptions" class="ag-theme-custom-vue"
        :default-col-def="defaultColDef" :floating-filter="true" row-selection="multiple" :pagination="true"
        :pagination-page-size="paginationPageSize" :suppress-pagination-panel="true" :row-drag-managed="false"
        :animate-rows="true" :modules="modules" @row-drag-end="onRowDragEnd" @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered" :row-data="rowData" :enable-rtl="rtl"
        suppress-column-virtualisation="true" />

      <div class="flex justify-between items-center mt-6">
        <div class="mr-4 ag-grid-table-actions-left flex items-center">
          <p class="mr-3 w-48">
            عرض المعلومات بالجدول
          </p>
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-2 w-32 border border-solid rounded-lg cursor-pointer flex items-center justify-between">
              <!-- <span class="mr-4 w-24" style="direction: ltr;">{{currentPage * paginationPageSize - (paginationPageSize - 1)}}  -  {{rowData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rowData.length}}  of  {{ rowData.length }}</span> -->
              <span class="mr-3 ">{{
                currentPage * paginationPageSize - (paginationPageSize - 1)
              }} - {{
  rowData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rowData.length
}}</span>
              <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <span class="ml-4 w-24"> من {{ rowData.length }}</span>
        </div>

        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </div>

      <div class="flex justify-center items-center mt-6">
        <slot name="checkButton" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import utilities from "@/app/shared/utilities";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

export default {
  components: {
    AgGridVue
  },
  props: {
    formTableCheck: {
      type: Boolean,
      // default: false
    },
    gridOptions: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      default() {
        return {
          add: null,
          delete: null
        };
      }
    },
    rowData: {
      type: Array,
      required: true
    },
    rtl: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchQuery: '',
      maxPageNumbers: 7,
      gridApi: null,
      columnApi: null,
      gridColumnApi: null,
      breadcrumb: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        // suppressSizeToFit: true,
        // autoSizeColumns: true,
        filterParams: {
          buttons: ['apply', 'reset']
        }
      },
      showCompleted: true,
      modules: [ClientSideRowModelModule]
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned('email', null);
      } else this.gridOptions.columnApi.setColumnPinned('email', 'left');
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {

    //..............................Tow Way For Auto Size Columns........................................
    // @first-data-rendered="onFirstDataRendered"

    onGridReady(params) {
      // this.gridApi = params.api;
      // this.gridColumnApi = params.columnApi;
      // this.sizeColumnsToFit();
    },

    onFirstDataRendered() {
      this.gridApi.paginationSetPageSize(Number(10));

      // without header
      // this.autoSizeAll(false);

      // with header
      // this.autoSizeAll(true);
    },

    sizeColumnsToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },

    //..............................Tow Way For Auto Size Columns........................................


    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    addNewRecord() {
      this.$emit('emitAddNewRecord');
    },
    showCurrentRecords() {
      this.showCompleted = true;
      this.showChecked = true;
      this.$emit('emitShowCurrentRecords');
    },
    showCompletedRecords() {
      this.showCompleted = false;
      this.$emit('emitShowCompletedRecords');
    },
    showDetails() {
      this.$emit('emitShowDetails');
    },
    deleteMultipleRecords(recordsIds) {
      this.$emit('emitDeleteMultipleRecords', recordsIds);
    },
    confirmDeleteMultipleRecords() {
      if (!this.gridOptions.api.getSelectedRows().length)
        return;
      let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
      let title = ' هل أنت متأكد من حذف ' + recordsIds.length + '  من العناصر ؟ ';
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: title,
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteMultipleRecords,
        acceptText: 'حذف',
        parameters: [recordsIds]
      });
    },
    onRowDragEnd(e) {
      console.log('onRowDragEnd', e);
      console.log(this.rowData.map(i => i.id));
    },
    checkAgGridEvent(event) {
      return this.$listeners && this.$listeners[event];
    },
    isAdmin() {
      return utilities.isAdmin();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApi.showLoadingOverlay();
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    let allColumnIds = [];
    let columnsWidth = 0;
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      columnsWidth += column.getActualWidth();
      allColumnIds.push(column.getColId());
    });
    if (this.$store.state.windowWidth >= columnsWidth)
      this.gridOptions.api.sizeColumnsToFit();

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
  created() {
    if (!this.rtl) {
      this.gridOptions.columnDefs.reverse();
    }
  }
};
</script>


<style lang="scss">
@import "ag-grid-community/dist/styles/ag-grid.scss";
@import "ag-grid-community/dist/styles/ag-theme-material/sass/_ag-theme-material-mixin.scss";
@import "./myStyle.scss";
</style>
