import {httpClient} from "../../shared/shared-services/httpClient";

const sharedMutations = {
  LOGIN(state, payload) {
    state.user.id = payload.user.id;
    state.user.token = payload.token;
    // Store user object beacuse vuex-presistedstae library doesnt active in the first
    localStorage.setItem('user', JSON.stringify(payload));
    // Set the authorization to axios header
    httpClient.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
  },
  LOGOUT(state) {
    Object.keys(state.user).forEach((i) => state.user[i] = null);

    localStorage.removeItem('user');
    location.reload();
  },
  FCMTOKEN(state, payload) {
    state.user.deviceToken = payload;
  },
  USERINFO(state, payload) {
    state.user.email = payload.email;
    state.user.fullName = payload.full_name;
    state.user.image_url = payload.image_url;
    state.user.first_name = payload.first_name;
    state.user.last_name = payload.last_name;
    state.user.gender = payload.gender;

  }
};

export default sharedMutations;

