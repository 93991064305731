const environment = {
  apiBaseURL: process.env.VUE_APP_API_BASE_URL,
  websiteApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'website/',
  donateApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'donate/',
  ecommerceApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'ecommerce/',
  quizApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'quiz/',
  eventApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'event/',
  barcodeApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'barcode/',
  donationsApiBaseUrl: process.env.VUE_APP_API_BASE_DONATION_URL + 'v1/cms/',
  communicationServiceApiBaseUrl : process.env.VUE_APP_API_BASE_COMMUNICATION_SERVICE_URL,
  rihabAlhabibApiBaseUrl: process.env.VUE_APP_API_BASE_RIHAB_ALHABIB_URL + 'api/v1/cms/',
  zadiApiBaseUrl: process.env.VUE_APP_ZADI_API_BASE_URL,
  env: process.env.ENV,
};

export {environment};
