import {websiteRoutes} from './website/website-index';
import {donateRoutes} from './donate/donate-index';
import {ecommerceRoutes} from "./ecommerce/ecommerce-index";
import {quizRoutes} from "./quiz/quiz-index";
import {eventRoutes} from "./event/event-index";
import {barcodeRoutes} from "./barcode/barcode-index";
import sharedRoutes from './shared/shared-routes';
import {donationsRoutes} from "./donations-website/donations-index";
import {jabiRoutes} from "./jabi/jabi-index";
import  {rihabAlhabibRoutes} from "@/app/rihab-alhabib/rihab-alhabib-index";
import  {zadiRoutes} from "./zadi/zadi-index";

const appRoutes = [
  {
    path: '/test-route',
    name: 'Home',
    component: () => import('../views/pages/Error404')
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: () => import('../views/pages/NotAuthorized')
  }
];

const ourRoutes = [...sharedRoutes, ...websiteRoutes, ...appRoutes, ...donateRoutes, ...ecommerceRoutes, ...quizRoutes, ...eventRoutes, ...barcodeRoutes, ...donationsRoutes ,...jabiRoutes, ...rihabAlhabibRoutes,...zadiRoutes];

export default ourRoutes;
