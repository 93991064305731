<template>
  <!-- <vs-card> -->
  <div>
    <vs-dropdown
      v-show="show"
      class="cursor-pointer"
      vs-trigger-click
    >
      <div
        class="p-3 per_drop_color cursor-pointer flex items-center justify-between font-medium"
      >
        <!-- <span class="mr-2">{{ selectedProject.showName }}</span> -->
        <feather-icon
          class="mr-4 iconColor"
          :icon="selectedTabsIcon"
          svg-classes="h-6 w-6"
        />
        <span class="mr-6">{{ selectedTabs }}</span>
        <feather-icon
          icon="ChevronDownIcon"
          svg-classes="h-5 w-5"
        />
      </div>
      <vs-dropdown-menu>
        <div
          v-for="tab in tabs"
          :key="tab.index"
        >
          <vs-dropdown-item
            color="#5E445A"
            class="p-2"
            @click="fetchAllForms(tab.checked)"
          >
            <feather-icon
              class="mr-4 iconColor2"
              :icon="tab.icon"
              svg-classes="h-6 w-6"
            />
            <span>{{ tab.label }}</span>
          </vs-dropdown-item>
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>


    <div v-show="!show">
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <h4 class="text-primary">
          حدد عدة استمارات لتغيير حالتها إلى
        </h4>
      </vs-row>
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <div class="demo-alignment">
          <vs-button
            @click="confirmCheckedMultipleRecords(status)"
            size="big"
            type="filled"
          >
            {{ btnName }}
          </vs-button>
        </div>
      </vs-row>
    </div>
  </div>
  <!-- </vs-card> -->
</template>

<script>
import {RepositoryFactory} from "../website-repositories/websiteRepositoryFactory";

const formsRepo = RepositoryFactory.get('forms');

export default {
  name: "CheckedFormsCard",
  props: {
    btnName: {type: String, required: true},
    status: {type: Boolean, required: true},
    isVoluntaryForm: {type: Boolean, default: false},
    gridOptions: {type: Object, required: true},
    show: {type: Boolean, default: false}
  },
  data() {
    return {
      tabs: [
        {
          name: 'checked',
          index: 0,
          // icon: 'icon-user-x',
          icon: 'UserXIcon',
          label: 'استمارات لم تتم مقابلتها',
          checked: false
        },
        {
          name: 'notChecked',
          index: 1,
          // icon: 'icon-user-check',
          icon: 'UserCheckIcon',
          label: 'استمارات تمت مقابلتها',
          checked: true
        }
      ],
      selectedTabs: 'استمارات لم تتم مقابلتها',
      selectedTabsIcon: 'UserXIcon'
    };
  },
  methods: {
    fetchAllForms(isChecked) {
      this.$emit('fetchAllForms', isChecked);

      if (isChecked == true) {
        this.selectedTabs = 'استمارات تمت مقابلتها';
        this.selectedTabsIcon = 'UserCheckIcon';
      } else {
        this.selectedTabs = 'استمارات لم تتم مقابلتها';
        this.selectedTabsIcon = 'UserXIcon';
      }
    },
    confirmCheckedMultipleRecords() {
      if (!this.gridOptions.api.getSelectedRows().length)
        return;
      let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
      let title = this.status ? ' هل أنت متأكد من عدم مقابلة ' + recordsIds.length + '  من الاستمارت ؟ ' : ' هل أنت متأكد من مقابلة ' + recordsIds.length + ' من الاستمارت ؟ ';
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: title,
        text: `في حال الموافقة, سيتم تغيير حالة الاستمارات `,
        acceptText: 'موافق',
        accept: this.checkedForm,
        parameters: [recordsIds]
      });
    },
    checkedForm(ids) {
      formsRepo.checkedForm(ids, this.status, true, this.isVoluntaryForm).then((response) => {
        if (response.code === 200) {
          if (this.status)
            this.fetchAllForms(true);
          else
            this.fetchAllForms();
        }
      });
    },
  }
};
</script>

<style scoped>
.per_drop_color {
  background: #5E445A;
  color: white;
  border-radius: 5px;
}

.iconColor {
  color: white;
}

.iconColor2 {
  color: #5E445A;
}

.ss {
  background: #5E445A;
  color: white;
}
</style>
