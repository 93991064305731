<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteFeedback($event, null , true)"
    />

    <vs-popup
      :active.sync="popupActive"
      title="تفاصيل الرسالة"
    >
      <vs-progress
        color="primary"
        indeterminate
        v-if="isLoading"
      />
      <ValidationObserver
        ref="validationObserver"
        v-slot="{handleSubmit}"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <image-uploader
            :image-preview="imagePreview"
            :is-image-required="imageRequired"
            @fileUploaded="fileUploaded"
            ref="imageUploader"
          />

          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="اسم الكاتب"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الكاتب</label>
                <vs-input
                  class="w-full"
                  placeholder="الكاتب"
                  v-model="feedback.author"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label>نوع المدونة</label>
              <ValidationProvider
                name="نوع المدونة"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :options="feedbackTypes"
                  @input="updateImageValidation"
                  label="label"
                  v-model="feedback.type"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full">
              <label>العمل</label>
              <vs-input
                class="w-full"
                placeholder="العمل"
                v-model="feedback.job"
              />
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="البريد الإلكتروني"
                rules="required"
                v-slot="{ errors }"
              >
                <label>البريد الإلكتروني</label>
                <vs-input
                  class="w-full"
                  placeholder="البريد الإلكتروني"
                  type="email"
                  v-model="feedback.email"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <ValidationProvider
                name="الرسالة"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الرسالة</label>
                <quill-editor v-model="feedback.message"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                @click.prevent="handleSubmit(onSubmit)"
                type="filled"
                ycolor="success"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup
      :active.sync="popupActiveDetails"
      title="تفاصيل الرسالة"
    >
      <form>
        <div class="vx-row">
          <img
            v-lazy="feedback.image_url"
            class="m-auto custom-image-size"
          >
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الكاتب : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ feedback.author }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>البريد الإلكتروني : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ feedback.email }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>النوع : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ feedback.type }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الوظيفة : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ feedback.job }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الشرح المفصّل : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <span v-html="feedback.message"/>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              @click="popupActiveDetails=false"
              type="filled"
              ycolor="success"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import imageUploader from '../../../shared/shared-components/ImageUploader';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import vSelect from "vue-select";
import utilities from "@/app/shared/utilities";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import 'quill/dist/quill.snow.css';
import {quillEditor} from 'vue-quill-editor';

const feedbackRepo = RepositoryFactory.get('feedback');
const sharedRepo = SharedRepositoryFactory.get('generalTypes');

export default {
  name: "FeedbackMain",
  components: {
    AgTable,
    vSelect,
    imageUploader,
    quillEditor,
  },
  data() {
    return {
      gridOptions: null,
      rowData: [],
      gridApi: null,
      popupActive: false,
      popupActiveDetails: false,
      isLoading: true,
      feedback: {
        id: null,
        type: null,
        message: '',
        author: '',
        image_url: '',
        email: '',
        job: '',
        mobile: ''
      },
      imagePreview: null,
      imageRequired: false,
      feedbackTypes: []
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          headerName: 'الصورة',
          field: 'image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'الناشر',
          field: 'author',
        },
        {
          headerName: 'النوع',
          field: 'type',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الرسالة',
          field: 'message',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'البريد  الإلكتروني',
          field: 'email',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteFeedback(id, rowIndex);
            },
            editRecord: function (feedback) {
              self.editRecord(feedback);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete']
          },
        },
      ];
    },

    deleteFeedback(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      feedbackRepo.deleteFeedback(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllFeedback();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.feedback);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.feedback.type = this.$route.params.key;
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(feedback) {
      this.$refs.imageUploader.clearImage();
      Object.assign(this.feedback, feedback);
      this.imagePreview = this.feedback.image_url;
      this.openPopup();
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.feedback = record;
    },

    updateImageValidation(value) {
      this.$refs.validationObserver.reset();
      this.imageRequired = value === "Testimonials";
    },

    onSubmit() {
      this.isLoading = true;

      if (typeof this.feedback.image_url == "string")
        delete this.feedback.image_url;

      if (this.feedback.id) {
        this.updateFeedback(this.feedback);
      } else {
        this.storeFeedback(this.feedback);
      }
    },

    storeFeedback(feedback) {
      feedbackRepo.storeFeedback(feedback).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    updateFeedback(feedback) {
      feedbackRepo.updateFeedback(feedback).then((response) => {
        let index = this.rowData.map((feedback) => {
          return feedback.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    handleResponse() {
      this.popupActive = false;
    },

    fileUploaded(file) {
      this.feedback.image_url = file;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    fetchAllFeedback() {
      feedbackRepo.fetchAllFeedback(this.$route.params.key).then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    sharedRepo.fetchGeneralTypes('feedback').then((data) => {
      this.feedbackTypes = data;
    });
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchAllFeedback();
      },
      {immediate: true}
    );

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};

</script>

<style scoped>

.custom-image-size {
  width: 40em;
}

</style>
