import moment from 'moment';
import {isArray, isNumber, isObject, isString} from "lodash";

export default {
  formatDate(date, format) {
    return moment(date).format(format);
  },
  initObjectMembers(object) {
    Object.keys(object).forEach((key) => {
      if (isString(object[key]) || isNumber(object[key]))
        object[key] = null;
      if (isArray(object[key]))
        object[key] = [];
      if (isObject(object[key]))
        this.initObjectMembers(object[key]);
    });
  },
  moveElementArray(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  },
  rgba2hex(color) {
    let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    let hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
    return hex;
  },
  transformToFormData(obj) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      if (value != null) {
        formData.append(`${key}`, `${value}`);
      }
    }
    if (obj.image_url) {
      formData.delete('image_url');
      formData.append('image_url', obj.image_url);
    }
    if (obj.image_preview_url) {
      formData.delete('image_preview_url');
      formData.append('image_preview_url', obj.image_preview_url);
    }
    return formData;
  },
  elapsedTime(startTime) {
    let x = new Date(startTime);
    let now = new Date();
    var timeDiff = now - x;
    timeDiff /= 1000;

    var seconds = Math.round(timeDiff);
    timeDiff = Math.floor(timeDiff / 60);

    var minutes = Math.round(timeDiff % 60);
    timeDiff = Math.floor(timeDiff / 60);

    var hours = Math.round(timeDiff % 24);
    timeDiff = Math.floor(timeDiff / 24);

    var days = Math.round(timeDiff % 365);
    timeDiff = Math.floor(timeDiff / 365);

    var years = timeDiff;

    if (years > 0) {
      return 'منذ ' + years + (years > 1 ? ' سنوات ' : ' سنة ');
    } else if (days > 0) {
      return 'منذ ' + days + (days > 1 ? ' أيام ' : ' يوم ');
    } else if (hours > 0) {
      return 'منذ ' + hours + (hours > 1 ? ' ساعات ' : ' ساعة ');
    } else if (minutes > 0) {
      return 'منذ ' + minutes + (minutes > 1 ? ' دقائق ' : ' دقيقة');
    } else if (seconds > 0) {
      return (seconds > 1 ? 'منذ ' + seconds + ' ثانية' : 'الآن');

    }
  },
  fetchRecordIndex(dataLength, index, action) {
    let record = {index: index};
    if (action == 'next')
      record.index++;
    else if (action == 'previous')
      record.index--;
    record.isPrevious = record.index <= 0 ? false : true;
    record.isNext = record.index < dataLength - 1 ? true : false;
    return record;
  },
  changeOrderStatus(order, isRejected) {
    let statusObject = {
      statusAction: '', newStatus: '', fromStatus: '',
      toStatus: '', fromStatusColor: '', toStatusColor: '',
    };
    if (!isRejected) {
      switch (order.status) {
        case 'Rejected': {
          statusObject.fromStatus = 'تم الرفض';
          statusObject.fromStatusColor = '#EF4141';
          break;
        }
        case 'Delivered': {
          statusObject.fromStatus = 'تم التوصيل';
          statusObject.fromStatusColor = '#9ECC3B';
          break;
        }
        case 'Review': {
          statusObject.statusAction = 'قبول الطلب';
          statusObject.newStatus = 'Approved';
          statusObject.fromStatus = 'قيد المراجعة';
          statusObject.toStatus = 'مقبول';
          statusObject.fromStatusColor = '#FE948E';
          statusObject.toStatusColor = '#5F7A99';
          break;
        }
        case 'Approved': {
          statusObject.fromStatus = 'مقبول';
          statusObject.fromStatusColor = '#5F7A99';
          statusObject.statusAction = ' تجهيز الطلب';
          statusObject.newStatus = 'Ready';
          statusObject.toStatus = 'جاهز';
          statusObject.toStatusColor = '#8158A4';
          break;
        }
        case 'Ready': {
          statusObject.fromStatus = 'جاهز';
          statusObject.fromStatusColor = '#8158A4';
          if (order.with_delivery) {
            statusObject.toStatus = 'قيد التوصيل';
            statusObject.statusAction = 'جار توصيل الطلب';
            statusObject.newStatus = 'UnderDelivery';
            statusObject.toStatusColor = '#6495ED';
          } else {
            statusObject.statusAction = 'توصيل الطلب';
            statusObject.newStatus = 'Delivered';
            statusObject.toStatus = 'تم التوصيل';
            statusObject.toStatusColor = '#9ECC3B';
          }
          break;
        }
        case 'UnderDelivery' : {
          statusObject.statusAction = 'توصيل الطلب';
          statusObject.newStatus = 'Delivered';
          statusObject.fromStatus = 'قيد التوصيل';
          statusObject.toStatus = 'تم التوصيل';
          statusObject.toStatus = 'تم التوصيل';
          statusObject.fromStatusColor = '#6495ED';
          statusObject.toStatusColor = '#9ECC3B';
          break;
        }
      }
    } else if (order.status === 'Review') {
      statusObject.newStatus = 'Rejected';
      statusObject.statusAction = 'رفض الطلب';
      statusObject.fromStatus = 'قيد المراجعة';
      statusObject.toStatus = 'مرفوض';
      statusObject.fromStatusColor = '#FE948E';
      statusObject.toStatusColor = '#EF4141';
    }
    return statusObject;
  },
  setOrderStatusColor(status) {
    switch (status) {
      case 'Review':
        return '#FE948E';
      case 'Approved':
        return '#5F7A99';
      case 'Ready' :
        return '#8158A4';
      case 'UnderDelivery':
        return '#6495ED';
      case 'Delivered':
        return '#9ECC3B';
      case 'Rejected':
        return '#EF4141';
    }
  },
  reverseDate(date) {
    let dateArr = date.substring(0, 10).split("-");
    let reverseDateArr = dateArr[2] + "." + dateArr[1] + "." + dateArr[0];
    return reverseDateArr.toString();
  },
  getFilterModel(params) {
    let filterValues, filterKeys, filterModel, setFilterValues = '';
    let array = [];
    if (Object.keys(params).length !== 0) {
      filterKeys = Object.keys(params);
      Object.values(params).forEach(item => {
        filterValues = (({filter}) => ({filter}))(item);
        if (item.filterType === 'set') {
          item.values.forEach(item => {
            setFilterValues += item + '|';
          });
          array.push(setFilterValues);
          setFilterValues = '';
        } else if (item.filterType === 'date') {
          if (item.type === 'equals') {
            array.push(this.reverseDate(item.dateFrom));
          }
        } else
          array.push(item.filter);
      });
      filterModel = '';
      filterValues = array;
      filterKeys.map((item, index) => {
        filterValues.map((i, j) => {
          if (index === j)
            filterModel += ('&filter[' + item + ']=' + i);
        });
      });
    }
    return filterModel;
  },
  getSortModel(params) {
    let sortModel;
    if (Object.keys(params).length !== 0) {
      sortModel = '';
      params.map((item) => {
        sortModel += ('&sort[' + item.colId + ']=' + item.sort);
      });
    }
    return sortModel;
  },
  //TODO refactor filter code
  getFilter(params) {
    let filterValues, filterKeys, filterModel, setFilterValues = '';
    let array = [];
    if (Object.keys(params).length !== 0) {
      filterKeys = Object.keys(params);
      Object.values(params).forEach((item, index) => {
        filterValues = (({filter}) => ({filter}))(item);
        if (item.filterType === 'set') {
          setFilterValues = item.values.join(',');
          array.push({type: item.filterType, value: setFilterValues});
          setFilterValues = '';
        } else if (item.filterType === 'date') {
          if (item.type === 'equals') {
            array.push({type: item.filterType, value: item.dateFrom});
          }
        } else
          array.push({type: item.filterType, value: item.filter});
      });
      filterModel = '';
      filterValues = array;
      filterKeys.map((key, index) => {
        filterValues.map((item, j) => {
          if (index === j)
            if (item.type === 'set')
              filterModel += `&filter=${key}||$in||${item.value}`;
            else if (item.type === 'number')
              filterModel += `&filter=${key}||$eq||${item.value}`;
            else if (item.type === 'date') {
              const date = moment(item.value);
              const startDay = date.startOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
              const endDay = date.endOf('day').format('YYYY-MM-DD[T]HH:mm:ss');
              console.log(endDay);
              filterModel += `&filter=${key}||$gte||${startDay}&filter=${key}||$lte||${endDay}`;
            } else
              filterModel += `&filter=${key}||$cont||${item.value}`;
        });
      });
    }
    return filterModel;
  },
  getSort(params) {
    let sortModel;
    if (Object.keys(params).length !== 0) {
      sortModel = '';
      params.map((item) => {
        sortModel += ('&sort=' + item.colId + ',' + item.sort.toUpperCase());
      });
    }
    return sortModel;
  },
  getQueryParameters(params, perPage, page, isConditionFilter = false) {
    let filter, sort;
    if (isConditionFilter) {
      filter = this.getFilter(params.request.filterModel);
      sort = this.getSort(params.request.sortModel);
      if (filter === undefined)
        filter = '';
      if (sort === undefined)
        sort = '';
      return `?limit=${perPage}` + `&page=${page}` + filter + sort;
    } else {
      filter = this.getFilterModel(params.request.filterModel);
      sort = this.getSortModel(params.request.sortModel);
      if (filter === undefined)
        filter = '';
      if (sort === undefined)
        sort = '';
      return `?perPage=${perPage}` + `&page=${page}` + filter + sort;
    }
  },
  isAdmin() {
    const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
    if (roles.includes('super_admin'))
      return true;
  },
  checkPermission(permission) {
    if (this.isAdmin())
      return true;
    else if (JSON.parse(localStorage.getItem('user')).user.permissions.map((permission) => permission.name).includes(permission))
      return true;
  },
  getChipColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case  'CANCELED':
        return 'danger';
      case 'USED':
        return 'primary';
      case 'PAID' :
        return '#5F7A99';
      case 'NOT_PAID':
        return '#8158A4';
      case 'INVITATION' :
        return '#FE948E';
    }
  },
  dateFormatter(date) {
    return date.substring(0, 19).replace("T", "-");
  },
  checkTargetAmount(targetAmount, collectedAmount) {
    if (parseInt(collectedAmount) > parseInt(targetAmount)) {
      return true;
    }
  }
};
