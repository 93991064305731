<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitDeleteMultipleRecords="deleteFeedback($event, null , true)"
    />

    <vs-popup
      :active.sync="popupActiveDetails"
      title="تفاصيل الرسالة"
    >
      <form>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>اسم المرسل : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <h4>{{ feedback.author }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>البريد الإلكتروني : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <h4>{{ feedback.email }}</h4>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>رقم الموبايل : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <h4>{{ feedback.mobile }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الرسالة : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <span v-html="feedback.message"/>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              @click="popupActiveDetails=false"
              type="filled"
              ycolor="success"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";

const feedbackRepo = RepositoryFactory.get('feedback');
export default {
  name: "FeedbackMain",
  components: {
    AgTable
  },
  data() {
    return {
      gridOptions: null,
      rowData: [],
      gridApi: null,
      popupActiveDetails: false,
      isLoading: true,
      feedback: {
        id: null,
        type: null,
        message: '',
        author: '',
        image_url: '',
        email: '',
        mobile: ''
      },
      imagePreview: null
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          headerName: 'الاسم',
          field: 'author',
        },
        {
          headerName: 'الرسالة',
          field: 'message',
          width: 350,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'البريد  الإلكتروني',
          field: 'email',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الموبايل',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteFeedback(id, rowIndex);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'delete']
          },
        },
      ];
    },

    deleteFeedback(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      feedbackRepo.deleteFeedback(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllFeedback();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.feedback = record;
    },
    fetchAllFeedback() {
      feedbackRepo.fetchAllFeedback('Message').then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllFeedback();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};

</script>

<style scoped>

.custom-image-size {
  width: 40em;
}

</style>
