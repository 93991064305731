const barcodeRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '/barcode',
        component: () => import('./barcode-views/barcodeMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'قارئ الباركود', active: true}
          ],
          pageTitle: 'المشاريع'
        }
      }
    ]
  }
];
export default barcodeRoutes;
