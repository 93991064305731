const donateRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '/donation',
        component: () => import('./donate-views/donate-donation/DonationMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول التبرعات الواردة', active: true}
          ],
          pageTitle: 'التبرعات الواردة'
        }
      },
      {
        path: '/donation-invites',
        component: () => import('./donate-views/donate-invites/DonationInvitesMain'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جدول دعوات التبرع', active: true}
          ],
          pageTitle: 'التبرع'
        }
      },

    ]
  }
];
export default donateRoutes;
