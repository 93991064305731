import blogRepository from "./blogRepository";
import tagRepository from "./tagsRepository";
import feedbackRepository from "./feedbackRepository";
import analyticsRepository from "./analyticsRepository";
import zadiRepository from "./zadiRepository";
import formsRepository from "./formsRepository";
import userRepository from "../../shared/shared-repositories/userRepository";


export const repositories = {
  blog: blogRepository,
  tag: tagRepository,
  feedback: feedbackRepository,
  analytics: analyticsRepository,
  zadi: zadiRepository,
  forms: formsRepository,
  user: userRepository,
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
