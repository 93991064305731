export const zadiRoutes = [
  {
    path: '/zadi',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: 'books',
        component: () => import('./views/books'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'ملخصات زادي', active: true}
          ],
          pageTitle: 'ملخصات زادي'
        }
      },
      {
        path: 'magazines',
        component: () => import('./views/magazines'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'أعداد مجلة زادي', active: true}
          ],
          pageTitle: 'أعداد مجلة زادي'
        }
      },
    ]
  }
];
