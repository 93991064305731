<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteVisitor($event, null , true)"
    />
    <vs-popup
      title="إضافة زائر"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="الاسم"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="الاسم"
                  v-model="visitor.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="رقم الموبايل"
                rules="required|length:10"
                v-slot="{ errors }"
              >
                <label>رقم الموبايل</label>
                <vs-input
                  type="number"
                  placeholder="رقم الموبايل"
                  dir="ltr"
                  v-model="visitor.mobile"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <label>المشروع</label>
              <ValidationProvider
                name="المشروع"
                :rules="'required'"
                v-slot="{ errors }"
              >
                <v-select
                  :options="projectOptions"
                  :reduce="name => name.value"
                  label="name"
                  v-model="visitor.project"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
    <vs-popup
      title="تفاصيل المدونة"
      :active.sync="popupActiveDetails"
    >
      <form>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ visitor.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ visitor.name }}</h4>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الرقم : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ visitor.mobile }}</h4>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>تاريخ التسجيل : </h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <h4>{{ visitor.created_at }}</h4>
          </div>
        </div>
        <div
          v-if="visitor.details"
          class="vx-row my-10"
        >
          <div class="vx-col sm:w-1/3 w-full">
            <h4>نتيجة عام 1442: </h4>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-2">
            <h4>{{ visitor.details.year_1442 }}</h4>
          </div>
        </div>
        <div
          v-if="visitor.details"
          class="vx-row my-10"
        >
          <div class="vx-col sm:w-1/3 w-full">
            <h4>نتيجة عام 1443: </h4>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-2">
            <h4>{{ visitor.details.year_1443 }}</h4>
          </div>
        </div>
        <div
          v-if="visitor.details"
          class="vx-row my-10"
        >
          <div class="vx-col sm:w-1/3 w-full">
            <h4>نتيجة عام 1444: </h4>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-2">
            <h4>{{ visitor.details.year_1444 }}</h4>
          </div>
        </div>
        <div
          v-if="visitor.details"
          class="vx-row my-10"
        >
          <div class="vx-col sm:w-1/3 w-full">
            <h4>نتيجة عام 1445: </h4>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-2">
            <h4>{{ visitor.details.year_1445 }}</h4>
          </div>
        </div>
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>النتيجة الكلية : </h4>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-2">
            <h4>{{ visitor.score }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المشروع : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ visitor.project }}</h4>
          </div>
        </div>


        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              ycolor="success"
              type="filled"
              @click="popupActiveDetails=false"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import zadiRepository from "@/app/website/website-repositories/zadiRepository";
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import utilities from '../../../shared/utilities/index';
import vSelect from 'vue-select';

const zadiRepo = RepositoryFactory.get('zadi');

export default {
  name: "ZadiMain",
  components: {
    AgTable,
    vSelect
  },
  data() {
    return {
      gridOptions: null,
      rowData: [],
      isLoading: true,
      popupActive: false,
      popupActiveDetails: false,
      projectOptions: [
        {
          name: 'رحاب الحبيب',
          value: 'rehabAlHabeb'
        },
        {
          name: 'زادي',
          value: 'zadi'
        }
      ],
      visitor: {
        id: null,
        name: '',
        mobile: '',
        details: {
          year_1442: null,
          year_1443: null,
          year_1444: null,
          year_1445: null,
        },
        score: null,
        project: '',
        created_at: ''
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'المشروع',
          field: 'project',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الهاتف المحمول',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'تاريخ التسجيل',
          field: 'created_at',
          filter: 'agTextColumnFilter',
          valueFormatter: self.dateFormatter
        },
        {
          headerName: 'نتيجة عام 1442',
          field: 'details.year_1442',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'نتيجة عام 1443',
          field: 'details.year_1443',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'نتيجة عام 1444',
          field: 'details.year_1444',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'نتيجة عام 1445',
          field: 'details.year_1445',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'النتيجة الكلية',
          field: 'score',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteVisitor(id, rowIndex);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ["view", "delete"],
          },
        },
      ];
    },
    dateFormatter(params) {
      if (params.data.created_at)
        return utilities.dateFormatter(params.data.created_at);
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.visitor = record;
    },
    deleteVisitor(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      zadiRepo.deleteVisitor(ids).then((response) => {

        this.isLoading = false;
        if (response.code === 200) {
          if (isMultiple) {
            this.fetchVisitors();
          } else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    onSubmit() {
      this.isLoading = true;
      zadiRepo.storeVisitor(this.$objectToFD(this.visitor)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    fetchVisitors() {
      zadiRepository.fetchAllVisitors(this.$route.params.key).then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    },
    createRecord() {
      utilities.initObjectMembers(this.visitor);
      this.visitor.project = this.$route.params.key;
      this.openPopup();
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchVisitors();
      },
      {immediate: true}
    );
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
