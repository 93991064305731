<template>
  <ValidationProvider
    :name="name||placeholder"
    :rules="validationRules"
    v-slot="{ errors }"
  >
    <label>{{ name }}</label>
    <v-select
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :label="label"
      :multiple="multiple"
      :options="options"
      :placeholder="placeholder"
      :reduce="reduce"
      v-model="computedValue"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: "TmSelect",
  props: {
    name: {type: String, required: false, default: null},
    value: {type: [Number, String, Array, Object], required: true},
    placeholder: {type: String, default: null},
    validationRules: {type: [String, Object], required: true},
    options: {type: Array, required: true},
    label: {type: String, default: null},
    reduce: {type: Function, default: null},
    multiple: {type: Boolean, default: false}
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },

  components: {
    vSelect
  }
};
</script>
