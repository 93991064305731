<template>
  <div>
    <vs-row
      vs-align="center"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="8"
      >
        <img
          v-if="getImage() != null"
          :src="getImage()"
          alt="img"
          class="responsive rounded"
        >
        <img
          v-else
          style="cursor: pointer; width:50%"
          src="../../../../src/assets/images/addphoto.png"
          @click="$refs.updateImgInput.click()"
          alt="img"
        >
      </vs-col>
    </vs-row>

    <div class="modify-img flex justify-between mt-5">
      <ValidationProvider
        name="الصورة"
        ref="imageValidation"
        :rules="`${(!imagePreview && isImageRequired) ? 'required' :''}`"
        v-slot="{errors, validate}"
      >
        <input
          type="file"
          class="hidden"
          ref="updateImgInput"
          @change="updateCurrImg($event); validate($event)"
          accept="image/*,application/json"
        >
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="mx-auto flex items-center justify-center">
      <vs-button
        class="mr-4"
        @click="$refs.updateImgInput.click()"
      >
        تعديل الصورة
      </vs-button>
      <vs-button
        v-if="!imagePreview"
        color="danger"
        type="filled"
        @click="clearImage"
      >
        حذف الصورة
      </vs-button>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  props: {
    imagePreview: {
      type: String,
      default: null
    },
    isImageRequired: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: null
    },
    secondIndex: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      image: null
    };
  },

  methods: {
    // used to clear image after click on clear image button
    clearImage() {
      this.$emit('fileUploaded', null, this.index, this.secondIndex);
      this.image = null;
      this.imagePreview = null;
      this.$refs.imageValidation.reset();
      this.$refs.imageValidation.value = '';
      this.$refs.updateImgInput.value = null;
    },
    // used to update previewed image
    updateCurrImg(e) {
      this.image = URL.createObjectURL(e.target.files[0]);
      this.$emit('fileUploaded', e.target.files[0], this.index, this.secondIndex);
    },

    getImage() {
      if (this.image)
        return this.image;
      if (this.imagePreview)
        return this.imagePreview;
      return null;
    },
  },
};
</script>

<style scoped>

</style>
