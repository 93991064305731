export const jabiRoutes = [
  {
    path: '/jabies',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '',
        component: () => import('./views/jabies'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جوابي التميز', active: true}
          ],
          pageTitle: 'جوابي التميز'
        }
      },
      {
        path: '/jabies/:id/currentOrders',
        component: () => import('./views/currentOrders'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جوابي التميز', url: '/jabies'},
            {title: 'طلبات التبرع الحالية', active: true}
          ],
          pageTitle: 'طلبات التبرع الحالية'
        }
      },
      {
        path: '/jabies/:id/finishedOrders',
        component: () => import('./views/finishedOrders'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'جوابي التميز', url: '/jabies'},
            {title: 'طلبات التبرع المنتهية', active: true}
          ],
          pageTitle: 'طلبات التبرع المنتهية'
        }
      },
    ]
  }
];
