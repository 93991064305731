const rihabAlhabibRoutes = [
  {
    path: '/rihab-alhabib',
    component: () => import((`../../layouts/main/Main`)),
    children: [
      {
        path: '/rihab-alhabib/users',
        component: () => import('./views/users/users'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: 'المستخدمون', active: true}
          ],
          pageTitle: 'المستخدمون'
        }
      },
      {
        path: '/rihab-alhabib/statistics',
        component: () => import('./views/statistics'),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: 'الإحصائيات', active: true}
          ],
          pageTitle: 'الإحصائيات'
        }
      },

    ]
}];
export default rihabAlhabibRoutes;
