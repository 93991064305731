const ecommerceRouts = [
  {
    path: '/ecommerce',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '',
        component: () => import('./views/Home'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
          ],
          pageTitle: 'الصفحة الرئيسية'
        }
      },
      {
        path: '/ecommerce/market',
        component: () => import('./views/Markets/Market'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المتاجر', active: true}
          ],
          pageTitle: 'المتاجر'
        }
      },
      {
        path: '/ecommerce/products',
        component: () => import('./views/products/Product'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المنتجات', active: true}
          ],
          pageTitle: 'المنتجات'
        }
      },
      {
        path: '/ecommerce/stores',
        component: () => import('./views/Stores/Store'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المحال', active: true}
          ],
          pageTitle: 'المحال'
        }
      },
      {
        path: '/ecommerce/storeAdmins/:id',
        component: () => import('./views/Stores/StoreAdmins'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المحال', url: '/ecommerce/stores'},
            {title: 'مدراء المحل', active: true}
          ],
          pageTitle: 'مدراء المحل'
        }
      },
      {
        path: '/ecommerce/personal',
        component: () => import('./views/Orders/PersonalOrder'),
        name: 'personal',
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: ' شخصي', active: true}
          ],
          pageTitle: 'الطلبات'
        }
      },
      {
        path: '/ecommerce/friend_gift',
        component: () => import('./views/Orders/FriendGiftOrder'),
        name: 'friend_gift',
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'هدية لصديق', active: true}
          ],
          pageTitle: 'الطلبات'
        }
      },
      {
        path: '/ecommerce/orphan_gift',
        component: () => import('./views/Orders/OrphanGiftOrder'),
        name: 'orphan_gift',
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'هدية ليتيم', active: true}
          ],
          pageTitle: 'الطلبات'
        }
      },
      {
        path: '/ecommerce/orders/:id/order_details/:orderType',
        component: () => import('./views/Orders/OrderDetail'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'الطلبات', name: 'orders'},
            {title: 'تفاصيل الطلب', active: true}
          ],
          pageTitle: 'تفاصيل الطلب'
        }
      },
      {
        path: '/ecommerce/categories',
        component: () => import('./views/Categories/Category'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'اصناف المنتجات', active: true}
          ],
          pageTitle: 'اصناف المنتجات'
        }
      },
      {
        path: '/ecommerce/variant',
        component: () => import('./views/variant/variantKey'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'الخصائص', active: true}
          ],
          pageTitle: 'الخصائص'
        }
      },
      {
        path: '/ecommerce/products/:id/sub_products',
        name: 'sub_products',
        component: () => import('./views/SubProducts/SubProduct'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المنتجات', url: '/ecommerce/products'},
            {title: 'المنتجات الفرعية', active: true}

          ],
          pageTitle: 'المنتجات الفرعية'
        }
      },
      {
        path: '/ecommerce/products/:id/sub_products/:subProductId/',
        component: () => import('./views/SubProducts/SubProductDetails'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
          ],
          pageTitle: 'تفاصيل المنتج الفرعي'
        },
        children: [
          {
            name: 'subProductImages',
            path: 'images',
            component: () => import('./views/SubProducts/SubProductImage'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name: 'sub_products'},
                {title: 'صور المنتج الفرعي', active: true}

              ],
              pageTitle: ' صور المنتج الفرعي'
            }
          },
          {
            name: 'subProductTags',
            path: 'tags',
            component: () => import('./views/SubProducts/SubProductTag'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name: 'sub_products'},
                {title: 'تاغات المنتج الفرعي', active: true}

              ],
              pageTitle: 'تاغات المنتج الفرعي'
            }
          },
          {
            name: 'subProductFeedbacks',
            path: 'feedbacks',
            component: () => import('./views/SubProducts/SubProductFeedback/SubProductFeedback'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name: 'sub_products'},
                {title: 'مراجعات المنتج الفرعي', active: true}

              ],
              pageTitle: 'مراجعات المنتج الفرعي'
            }
          },
          {
            name: 'subProductVariants',
            path: 'variants',
            component: () => import('./views/SubProducts/SubProductVariant'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name: 'sub_products'},
                {title: 'خصائص المنتج الفرعي', active: true}

              ],
              pageTitle: 'خصائص المنتج الفرعي'
            }
          }
        ]
      },
      {
        path: '/ecommerce/variant/:id/variant_value',
        component: () => import('./views/varientValue/VariantValue'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الخصائص', url: '/ecommerce/variant'},
            {title: 'قيم الخصائص', active: true}
          ],
          pageTitle: 'قيم الخصائص'
        }
      },
      {
        path: '/ecommerce/packageSizes',
        component: () => import('./views/Packaging/PackageSize'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'حجوم التغليف', active: true}
          ],
          pageTitle: 'حجوم التغليف'
        }
      },
      {
        path: '/ecommerce/packageSize/:id/packageClasses',
        component: () => import('./views/Packaging/PackageClass'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'حجوم التغليف', url: '/ecommerce/packageSizes'},
            {title: 'فئات حجم التغليف', active: true}
          ],
          pageTitle: 'فئات حجم التغليف'
        }
      }
    ]
  },

];
export default ecommerceRouts;
