import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.websiteApiBaseUrl;
export default {

  fetchAllVisitors(project) {
    let url = `${URL}visitor?filters[project]=${project}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeVisitor(visitor) {
    const url = URL + 'visitor';
    return httpClient.post(url, visitor)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteVisitor(visitorId) {
    const url = URL + 'visitor/' + visitorId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  // exportRehabAlhabebResults() {
  //   const url = URL + "visitors/export";
  //   return httpClient.get(url, {responseType: 'arraybuffer'})
  //     .then(res => {
  //       let fileURL = window.URL.createObjectURL(new Blob([res.data]));
  //       let fileLink = document.createElement('a');
  //       fileLink.href = fileURL;
  //       fileLink.setAttribute('download', 'Rehab_Alhabeb_Results.xlsx');
  //       document.body.appendChild(fileLink);
  //       fileLink.click();
  //     })
  //     .catch(error => console.log(error));
  // }
};
