import Vue from 'vue';
import App from './App.vue';
import progressBar from "./app/shared/utilities/progressBar";
import utilities from "./app/shared/utilities/index";
// @ts-ignore
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css';
import 'vuesax/dist/vuesax.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import loadingOverlay from "./app/shared/utilities/loadingOverlay";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import Validations from './plugins/veeValidate/Vee-validate';
import Toasted from 'vue-toasted';
// @ts-ignore
import VueProgressBar from 'vue-progressbar';
import firebaseMessaging from './firebase';
import {createPinia, PiniaVuePlugin} from 'pinia';
import axios from "./axios.js";
import '../themeConfig.js';
import './globalComponents.js';
import './assets/scss/main.scss';
import '@/assets/css/main.css';
import router from './router';
import store from './store/store';
import {VueHammer} from 'vue2-hammer';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
// @ts-ignore
import ObjectToFD from 'vue-object-to-formdata';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueCompositionAPI from '@vue/composition-api';
import VueLazyload from 'vue-lazyload';

require('./assets/css/iconfont.css');
Vue.use(Vuesax);
Validations.initValidations();
Vue.use(Toasted);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueProgressBar, progressBar.getProgressBarOptions());
Vue.use(VueHammer);

Vue.use(VueLodash, {name: 'custom', lodash: lodash});
Vue.use(ObjectToFD);
Vue.use(VueLoading, loadingOverlay.getOverlayOptions());

const loadimage = require('./assets/images/loading.svg');
const errorimage = require('./assets/images/failure-loading.svg');

Vue.use(VueLazyload);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.directive('can', {

  bind: function (el, binding, vnode) {
    if (!utilities.isAdmin() && binding.value !== null) {
      // @ts-ignore
      if (!JSON.parse(localStorage.getItem('user')).user.permissions.map((permission) => permission.name)
        .includes(binding.value)) {
        // @ts-ignore
        vnode.elm.style.display = "none";
      }
    }
  }
});

// @ts-ignore
Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.prototype.$messaging = firebaseMessaging;

// @ts-ignore
export default new Vue({
  router,
  store,
  render: h => h(App),
  // @ts-ignore
  pinia
}).$mount('#app');
