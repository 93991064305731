const sharedState = {
  user: {
    token: null,
    id: -1,
    deviceToken: null,
    fullName: null,
    email: null,
    image_url: null,
    first_name: null,
    last_name: null,
    gender: null
  },
};

export default sharedState;
