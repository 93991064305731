import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.apiBaseURL + 'slider';

export default {

  fetchAllSliders() {
    return httpClient.get(URL)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeSlider(slider) {
    return httpClient.post(URL, slider)
      .then(res => res)
      .catch(error => console.log(error));
  },
  updateSlider(slider, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, slider)
      .then(res => res)
      .catch(error => console.log(error));
  },
  deleteSlider(sliderId, isImage = false) {
    let url = URL + '/' + sliderId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  }
};
