import authRepo from '../shared-repositories/authRepository';
import userRepo from '../shared-repositories/userRepository';

const sharedActions = {
  login({commit}, payload) {
    return authRepo.login(payload).then((response) => {
      commit('LOGIN', response);

    });
  },
  addDeviceToken({commit}, payload) {
    return authRepo.addDeviceToken(payload).then(() => {
      commit('FCMTOKEN', payload);
    });
  },
  logout({commit}, payload) {
    if (payload === null)
      payload = 'null';
    return authRepo.logout(payload).then(() => {
      commit('LOGOUT');
    });
  },
  getUserInfo({commit}) {
    return userRepo.getCurrentUser().then((response) => {
      commit('USERINFO', response);
    });
  }
};

export default sharedActions;
