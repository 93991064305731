const eventRoutes = [
  {
    path: '/',
    component: () => import('../../layouts/main/Main'),
    children: [
      {
        path: '/eventsDefinition',
        component: () => import('./event-views/eventsDefinition.vue'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الحفلات', active: true}
          ],
          pageTitle: 'الحفلات'
        }
      },
      {
        path: '/eventUsers/:id',
        component: () => import('./event-views/eventUsers.vue'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الحفلات', url: '/eventsDefinition'},
            {title: 'الحجوزات', active: true}
          ],
          pageTitle: 'الحجوزات'
        }
      },
      {
        path: '/events/:id/name/:name',
        component: () => import('./event-views/events.vue'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الحفلات', url: '/eventsDefinition'},
            {title: 'أوقات الحفلة', active: true}
          ],
          pageTitle: 'أوقات الحفلة'
        }
      },
      {
        path: '/statistics/:id',
        component: () => import('./event-views/statistics.vue'),
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الحفلات', url: '/eventsDefinition'},
            {title: 'الإحصائيات', active: true}
          ],
          pageTitle: 'الإحصائيات'
        }
      },
      {
        path: '/dailyStatistics/:id',
        component: () => import('./event-views/statistics.vue'),
        meta : {
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'أوقات الحفلة', url: '/eventsDefinition'},
            { title:'الإحصائيات', active: true }
          ],
          pageTitle: 'الإحصائيات'
        }
      },
    ]
  }
];
export default eventRoutes;
