<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteBlog($event, null , true)"
    />

    <vs-popup
      :active.sync="popupActive"
      fullscreen
      title="تفاصيل المدونة"
    >
      <vs-progress
        color="primary"
        indeterminate
        v-if="isLoading"
      />
      <ValidationObserver
        ref="validationObserver"
        v-slot="{handleSubmit}"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full text-center">
              <h4 class="my-5">
                الصورة الأساسية
              </h4>
              <image-uploader
                :image-preview="imagePreview"
                :is-image-required="imageRequired"
                @fileUploaded="fileUploaded"
                ref="imageUploader"
              />
            </div>

            <div class="vx-col sm:w-1/2 w-full text-center">
              <h4 class="my-5">
                الصورة المعروضة
              </h4>
              <image-uploader
                :image-preview="imagePreview2"
                :is-image-required="imageRequired"
                @fileUploaded="fileUploaded2"
                ref="secImageUploader"
              />
            </div>
          </div>


          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full ">
              <label>العنوان</label>
              <vs-input
                class="w-full"
                name="title"
                placeholder="العنوان"
                v-model="blog.title"
              />
            </div>

            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="الأولوية"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الأولوية</label>
                <vs-input
                  class="w-full"
                  placeholder="الأولوية"
                  type="number"
                  v-model="blog.priority"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <ValidationProvider
                name="الشرح القصير"
                rules="required"
                v-slot="{ errors }"
              >
                <label>شرح قصير</label>
                <quill-editor v-model="blog.short_desc"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <label>الشرح المفصل</label>
              <quill-editor v-model="blog.full_desc"/>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label>نوع المدونة</label>
              <ValidationProvider
                name="نوع المدونة"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :options="blogTypes"
                  @input="updateImageValidation"
                  label="label"
                  v-model="blog.type"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label>الوسوم</label>
              <ValidationProvider
                name="التاغ"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  :options="tags"
                  :value="blog.tag"
                  @input="selected"
                  label="name"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2  mb-2">
              <ValidationProvider
                name="الوقت"
                rules="required"
                v-slot="{ errors }"
              >
                <label>التاريخ</label>
                <datepicker
                  class="w-full"
                  format="yyyy-MM-dd"
                  placeholder="إختر تاريخاً"
                  v-model="blog.date"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 mb-2 ">
              <label>رابط الفيديو</label>
              <vs-input
                class="w-full"
                name="title"
                placeholder="رابط الفيديو"
                v-model="blog.video_url"
              />
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                @click.prevent="handleSubmit(onSubmit)"
                type="filled"
                ycolor="success"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>


    <vs-popup
      :active.sync="popupActiveDetails"
      title="تفاصيل المدونة"
    >
      <form>
        <div class="vx-row">
          <img
            v-lazy="blog.image_url"
            class="m-auto custom-image-size"
          >
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.id }}</h4>
            <br>
          </div>


          <div class="vx-col sm:w-1/3 w-full">
            <h4>العنوان : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.title }}</h4>
            <br>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>التاغ : </h4>
            <br>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.tag_name }}</h4>
          </div>

          <hr style="height:1px;border-width:0;background-color:#BDBAC2">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>النوع : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.type }}</h4>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>التاريخ : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ blog.date }}</h4>
            <br>
          </div>

          <hr style="height:1px;border-width:0;background-color:#BDBAC2">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الأولوية : </h4>
          </div>
          <div class="vx-col sm:w-2/3  w-full mb-2">
            <h4>{{ blog.priority }}</h4>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الشرح القصير : </h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/1 w-full mb-2">
            <h4 v-html="blog.short_desc"/>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3  w-full">
            <h4>الشرح المفصّل : </h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/1 mb-2">
            <h4 v-html="blog.full_desc"/>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/3  w-full">
            <h4>رابط الفيديو : </h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/1 mb-2">
            <h4 v-html="blog.video_url"/>
          </div>
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-12">
          <div class="vx-col sm:w-1/4 mb-4">
            <vs-button
              @click="popupActiveDetails=false"
              type="filled"
              ycolor="success"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>
<script>

import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import 'quill/dist/quill.snow.css';
import {quillEditor} from 'vue-quill-editor';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import utilities from '../../../shared/utilities/index';
import imageUploader from '../../../shared/shared-components/ImageUploader';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";

const blogRepo = RepositoryFactory.get('blog');
const tagRepo = RepositoryFactory.get('tag');
const sharedRepo = SharedRepositoryFactory.get('generalTypes');

export default {
  name: "BlogMain",
  components: {
    AgTable,
    quillEditor,
    vSelect,
    Datepicker,
    imageUploader,
  },

  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActive: false,
      popupActiveDetails: false,
      dialogTitle: String,
      imagePreview: null,
      imagePreview2: null,
      blog: {
        id: null,
        title: '',
        image_url: '',
        video_url: '',
        image_preview_url: '',
        short_desc: '',
        full_desc: '',
        type: '',
        tag_id: '',
        date: '',
        priority: 1,
      },
      blogTypes: [],
      tags: [],
      imageRequired: true,
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          // rowDrag: true,
        },
        {
          headerName: 'الصورة',
          field: 'image_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'الصورة المعروضة',
          field: 'image_preview_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'العنوان',
          field: 'title',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'شرح مختصر',
          field: 'short_desc',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'التاغ',
          field: 'tag_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'النوع',
          field: 'type',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رابط الفيديو',
          field: 'video_url',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          width: 250,
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteBlog(id, rowIndex);
            },
            deleteImage: function (id) {
              self.deleteImage(id);
            },
            deletePreviewImage: function (id) {
              self.deletePreviewImage(id);
            },
            editRecord: function (blog) {
              self.editRecord(blog);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete', 'image-delete', 'image-preview-delete']
          },
        },
      ];
    },

    deleteBlog(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      blogRepo.deleteBlog(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllBlogs();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      });
    },
    deleteImage(id) {
      this.callDeleteImageRequest(id, 'images');
    },
    deletePreviewImage(id) {
      this.callDeleteImageRequest(id, 'images-preview');
    },
    callDeleteImageRequest(id, imageType) {
      this.isLoading = true;
      blogRepo.deleteImage(id, imageType).then((response) => {
        if (response.code === 200) {
          let recordIndex = this.rowData.findIndex(element => element.id == id);
          this.rowData[recordIndex].image_url = null;
        }
        this.isLoading = false;
        this.rerender();
      });
    },

    // used to detect tag details once clicked
    selected(tag) {
      this.blog.tag = tag;
      this.blog.tag_id = tag.id;
      this.blog.tag_name = tag.name;
    },

    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.blog);
      this.$refs.imageUploader.clearImage();
      this.$refs.secImageUploader.clearImage();

      this.imagePreview = null;
      this.imagePreview2 = null;
      this.dialogTitle = 'اضافة مدونة';
      this.blog.type = this.$route.params.key;
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(blog) {
      this.$refs.imageUploader.clearImage();
      this.$refs.secImageUploader.clearImage();
      this.updateImageValidation(blog.type);
      this.dialogTitle = 'تعديل مدونة';
      Object.assign(this.blog, blog);
      this.imagePreview = this.blog.image_url;
      this.imagePreview2 = this.blog.image_preview_url;
      this.blog.tag_id = blog.tag !== null ? blog.tag.id : null;
      this.openPopup();
    },

    updateImageValidation(value) {
      this.$refs.validationObserver.reset();
      this.imageRequired = value !== "FAQ";
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },

    onSubmit() {
      this.isLoading = true;

      if (this.blog.date != null)
        this.blog.date = utilities.formatDate(this.blog.date, 'yyyy-MM-DD');
      if (typeof this.blog.image_url == "string")
        delete this.blog.image_url;
      if (typeof this.blog.image_preview_url == "string")
        delete this.blog.image_preview_url;

      if (this.blog.id !== null) {
        this.updateBlog(this.blog);
      } else {
        this.storeBlog(this.blog);
      }
    },

    storeBlog(blog) {
      blogRepo.storeBlog(this.$objectToFD(blog)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },

    updateBlog(blog) {
      blogRepo.updateBlog(this.$objectToFD(blog), blog.id).then((response) => {
        let index = this.rowData.map((blog) => {
          return blog.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },

    fileUploaded(file) {
      this.blog.image_url = file;
    },

    fileUploaded2(file) {
      this.blog.image_preview_url = file;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.blog = record;
    },

    fetchAllBlogs() {
      blogRepo.fetchAllBlogs(this.$route.params.key).then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
    fetchAllTags() {
      tagRepo.fetchAllTags('website').then((response) => {
        this.tags = response;
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllTags();
    sharedRepo.fetchGeneralTypes('blog').then((data) => {
      this.blogTypes = data;
    });
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchAllBlogs();
      },
      {immediate: true}
    );

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
<style scoped>

.custom-image-size {
  width: 40em;
}

</style>
