import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.websiteApiBaseUrl;
export default {

  fetchAllTags(module) {
    const url = `${URL}tags?filters[module]=${module}`;
    return httpClient.get(url)
      .then(res => res.data['tags'])
      .catch(error => console.log(error));
  },
  storeTag(tag) {
    const url = URL + 'tags';
    return httpClient.post(url, tag)
      .then(res => res)
      .catch(error => console.log(error));
  },

  updateTag(tag, id) {
    const url = URL + `tags/${id}?_method=PUT`;
    return httpClient.post(url, tag)
      .then(res => res)
      .catch(error => console.log(error));
  },

  deleteTag(tagId) {
    const url = URL + 'tags/' + tagId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
};
