import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.websiteApiBaseUrl;

export default {
  fetchBlogByType(blogType, pageNumber, perPage) {
    const url = URL + 'blog/type/' + blogType + '?page=' + pageNumber + '&perPage=' + perPage;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },

  getBlogByTitle(title) {
    const url = URL + 'blog/' + title;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },

  fetchAllBlogsByType(blogType,) {
    const url = URL + 'blog/type/' + blogType;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },

  fetchAllBlogs(type) {
    let url = `${URL}blog/all?filters[type]=${type}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeBlog(blog) {
    const url = URL + 'blog';
    return httpClient.post(url, blog)
      .then(res => res)
      .catch(error => console.log(error));
  },

  updateBlog(blog, id) {
    const url = URL + `blog/` + id + `?_method=PUT`;
    return httpClient.post(url, blog)
      .then(res => res)
      .catch(error => console.log(error));
  },

  deleteBlog(blogId) {
    let url = URL + 'blog/' + blogId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
  deleteImage(blogId, imageType) {
    let url = `${URL}blog/${blogId}/${imageType}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  }
};
