import axios from 'axios';
import {environment} from "@/environment";
import progressBar from "../utilities/progressBar";
import toasted from "../utilities/toasted";
import loadingOverlay from "../utilities/loadingOverlay";

import router from '../../../router';

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
  baseURL: environment.apiBaseURL
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config);
// Set the authorization to axios header if not null
// get user object beacuse vuex-presistedstae library doesnt active in the first
let user = JSON.parse(localStorage.getItem('user'));
if (user !== null)
  httpClient.defaults.headers.common.Authorization = `Bearer ${user.token}`;
httpClient.defaults.headers.common['x-project-name'] = 'dashboard';


/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
  /** add auth token */
  return config;
};

const loggerInterceptor = config => {
  /** Add logging here */
  return config;
};
/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
// Add a request interceptor
httpClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  progressBar.start();
  if (loadingOverlay.loader) return config;
  loadingOverlay.start();
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    if (response.config.method !== 'get')
      if (response.data.message)
        toasted.success(response.data.message);
      else if (response.status === 200)
        toasted.success('تمت العملية بنجاح');
      else if (response.status === 201)
        toasted.success('تمت الإضافة بنجاح');
    progressBar.finish();
    loadingOverlay.hide();
    return response;
  },
  error => {
    progressBar.finish();
    loadingOverlay.hide();
    switch (error.response.status) {
      case 500:
        toasted.failed('حدث خطأ ما, يرجى المحاولة لاحقاً');
        break;
      case 422: {
        let errors = Object.values(error.response.data.errors);
        errors = errors.flat();
        errors.forEach((value) => {
          toasted.failed(value.toString());
        });
        break;
      }
      case 400 | 409: {
        if (error.response.data.message) {
          if (Array.isArray(error.response.data.message)) {
            let errors = Object.values(error.response.data.message);
            errors.forEach((value) => {
              Object.values(value).forEach((item) => {
                toasted.failed(Object.values(item));
              });
            });
          } else
            toasted.failed(error.response.data.message);
          }
          break;
        }
      case 404: {
        toasted.failed('حدث خطأ ما, يرجى المحاولة لاحقاً');
        break;
      }
      case 403: {
        switch (error.response.config.method) {
          case 'get':
            router.push('/not-authorized');
            break;
          case 'post':
          case 'put':
          case 'delete':
            toasted.failed('ليس لديك صلاحيةً');
        }
        break;
      }
      case 401: {
        localStorage.clear();
        router.push('/login');
        break;
      }
    }
    return Promise.reject(error);
  }
);

export {httpClient};
