<template>
  <div
    :style="{'direction':'rtl'}"
    class="flex justify-center items-center h-full"
  >
    <feather-icon
      icon="EyeIcon"
      svg-classes="h-7 w-7 hover:text-success cursor-pointer"
      @click="viewRecordDetails"
      v-show="actions.includes('view')"
      class="mx-2"
    />
    <div v-can="permissions.edit">
      <feather-icon
        icon="EditIcon"
        svg-classes="h-7 w-7 hover:text-primary cursor-pointer"
        @click="editRecord"
        v-show="actions.includes('edit')"
        class="mx-2"
      />
    </div>
    <div v-can="permissions.delete">
      <feather-icon
        icon="Trash2Icon"
        svg-classes="h-7 w-7 hover:text-danger cursor-pointer"
        @click="confirmDeleteRecord"
        v-show="actions.includes('delete')"
        class="mx-2"
      />
    </div>
    <feather-icon
      icon="ImageIcon"
      svg-classes="h-7 w-7 hover:text-danger cursor-pointer"
      @click="confirmDeleteImage(deletePreviewImage)"
      v-show="actions.includes('image-preview-delete')"
      class="mx-2"
    />
    <feather-icon
      icon="ImageIcon"
      svg-classes="h-7 w-7 hover:text-danger cursor-pointer"
      @click="confirmDeleteImage(deleteImage)"
      v-show="actions.includes('image-delete')"
      class="mx-2"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AgTableBtnCell",
  data() {
    return {
      actions: ['view', 'edit', 'delete', 'image-delete'],
      permissions: {
        edit: null,
        delete: null
      }
    };
  },
  methods: {
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'هل أنت متأكد من الحذف؟',
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteRecord,
        acceptText: 'حذف'
      });
    },
    confirmDeleteImage(action) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'هل أنت متأكد من حذف الصورة؟',
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: action,
        acceptText: 'حذف'
      });
    },
    deleteRecord() {
      this.params.deleteRecord(this.params.value, this.params.rowIndex);
    },
    deleteImage() {
      this.params.deleteImage(this.params.value, this.params.rowIndex);
    },
    deletePreviewImage() {
      this.params.deletePreviewImage(this.params.value, this.params.rowIndex);
    },
    editRecord() {
      this.params.editRecord(this.params.data);
    },
    viewRecordDetails() {
      this.params.viewRecordDetails(this.params.data);
    },
    isAdmin() {
      const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
      if ((roles.includes('super_admin')) || (roles.includes('group_manager')))
        return true;
      else return !!this.params.actions.includes('raven');
    }
  },
  created() {
    if (this.params.actions) this.actions = this.params.actions;
    if (this.params.permissions) this.permissions = this.params.permissions;
  }
});
</script>
