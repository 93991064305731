import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";
import utilities from '../../shared/utilities/index';

const URL = environment.websiteApiBaseUrl + 'feedback';
export default {

  fetchAllFeedback(type) {
    let url = `${URL}/all?filters[type]=${type}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error));
  },
  storeFeedback(feedback) {
    const feedbackAsformData = utilities.transformToFormData(feedback);
    return httpClient.post(URL, feedbackAsformData)
      .then(res => res)
      .catch(error => console.log(error));
  },

  updateFeedback(feedback) {
    const feedbackAsformData = utilities.transformToFormData(feedback);
    const url = URL + `/${feedback.id}?_method=PUT`;
    return httpClient.post(url, feedbackAsformData)
      .then(res => res)
      .catch(error => console.log(error));
  },

  deleteFeedback(feedbackId) {
    const url = URL + '/' + feedbackId;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error));
  },
};
