<template>
  <ValidationProvider :name="name||placeholder" :rules="validationRules" v-slot="{ errors }">
    <vs-input :label="name" :placeholder="placeholder" :type="type" class="w-full" v-model="computedValue" />
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TmInput",
  props: {
    name: { type: String, required: false, default: null },
    value: { type: null, required: true, nullable: true },
    type: { type: String, required: true },
    placeholder: { type: String, default: null },
    validationRules: { type: [String, Object], required: true }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
};
</script>
