<template>
  <div>
    <ag-table
      :form-table-check="true"
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitDeleteMultipleRecords="deleteForm($event, null , true)"
      @emitShowDetails="showDetails(0)"
    >
      <template v-slot:dropdown>
        <checked-card
          :btn-name="btnName"
          :grid-options="gridOptions"
          :status="status"
          @fetchAllForms="fetchAllForms(($event))"
          :show="true"
        />
      </template>


      <template v-slot:checkButton>
        <checked-card
          :btn-name="btnName"
          :grid-options="gridOptions"
          :status="status"
          @fetchAllForms="fetchAllForms(($event))"
          :show="false"
        />
      </template>
    </ag-table>


    <vs-popup
      title="تفاصيل الإستمارة"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.fullname"
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.address"
          />
        </div>

        <div>
          <label>الهاتف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.phone"
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.mobile"
          />
        </div>

        <div>
          <label>القيد :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.kayd"
          />
        </div>

        <div>
          <label>مكان الولادة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.birthplace"
          />
        </div>

        <div>
          <label>تاريخ الولادة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.birthday"
          />
        </div>

        <div>
          <label>الجنسية :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.nationality"
          />
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">المؤهلات والخبرات :</label>

        <div
          :key="item.id"
          v-for="item in form.qualifications_and_skills"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 mt-6"
        >
          <div>
            <label>الجهة :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.awarding"
            />
          </div>

          <div>
            <label>الشهادة العلمية :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.scientific_certificate"
            />
          </div>

          <div>
            <label>التخصص :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.specialization"
            />
          </div>
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">الخبرات السابقة :</label>

        <div
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
          :class="[index == form.previous_expertise.length-1 ? '':'bbb pb-8']"
          :key="index"
          v-for="(item,index) in form.previous_expertise"
        >
          <div>
            <label>الجهة المانحة :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.awarding "
            />
          </div>

          <div>
            <label>المسمى الوظيفي :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.position "
            />
          </div>
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">المواد التي تم تدريسها :</label>

        <div
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
          :class="[index == form.teaching_subjects.length-1 ? '':'bbb pb-8']"
          :key="index"
          v-for="(item,index) in form.teaching_subjects"
        >
          <div>
            <label>المادة :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.name"
            />
          </div>

          <div>
            <label>عدد الساعات :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.hours"
            />
          </div>
        </div>
      </div>


      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>


      <!-- <div class="mt-6 px-4">
                        <label >ملاحظات الزبون :</label>
                        <vs-textarea placeholder="الملاحظات" v-model="order.user_note" disabled  class="w-full mt-3" color="#5E445A"/>
                </div> -->
    </vs-popup>


    <vs-popup
      title="تفاصيل الإستمارة"
      :active.sync="popupActive"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.fullname"
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.address"
          />
        </div>

        <div>
          <label>الهاتف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.phone"
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.mobile"
          />
        </div>

        <div>
          <label>القيد :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.kayd"
          />
        </div>

        <div>
          <label>مكان الولادة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.birthplace"
          />
        </div>

        <div>
          <label>تاريخ الولادة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.birthday"
          />
        </div>

        <div>
          <label>الجنسية :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="form.nationality"
          />
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">المؤهلات والخبرات :</label>

        <div
          :key="item.id"
          v-for="item in form.qualifications_and_skills"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 mt-6"
        >
          <div>
            <label>الجهة :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.awarding"
            />
          </div>

          <div>
            <label>الشهادة العلمية :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.scientific_certificate"
            />
          </div>

          <div>
            <label>التخصص :</label>
            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.specialization"
            />
          </div>
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">الخبرات السابقة :</label>

        <div
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
          :class="[index == form.previous_expertise.length-1 ? '':'borderBottom pb-8']"
          :key="index"
          v-for="(item,index) in form.previous_expertise"
        >
          <div>
            <label>الجهة المانحة :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.awarding "
            />
          </div>

          <div>
            <label>المسمى الوظيفي :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.position "
            />
          </div>
        </div>
      </div>


      <div class="mt-8 px-4">
        <label class="sectionName">المواد التي تم تدريسها :</label>

        <div
          class="grid grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-6 mt-6"
          :class="[index == form.teaching_subjects.length-1 ? '':'borderBottom pb-8']"
          :key="index"
          v-for="(item,index) in form.teaching_subjects"
        >
          <div>
            <label>المادة :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.name"
            />
          </div>

          <div>
            <label>عدد الساعات :</label>


            <vs-input
              placeholder=""
              name="title"
              disabled
              color="#5E445A"
              class="w-full mt-3"
              v-model="item.hours"
            />
          </div>
        </div>
      </div>


      <div class="flex justify-center items-center mt-8 px-4 w-full">
        <vs-button
          class="mx-4"
          @click="showDetails(record.index,'previous')"
          type="filled"
          v-show="record.isPrevious"
          ycolor="success"
        >
          السابق
        </vs-button>

        <vs-button
          class="mx-4"
          @click="showDetails(record.index,'next')"
          type="filled"
          v-show="record.isNext"
          ycolor="success"
        >
          التالي
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../website-repositories/websiteRepositoryFactory";
import utilities from "../../../shared/utilities";
import checkedCard from "../../website-components/checkedFormsCard";
import AgSwitch from "@/app/shared/shared-components/ag-grid/AgTableSwitchCell";

const formsRepo = RepositoryFactory.get('forms');

export default {
  name: "TrainingForm",

  components: {
    AgTable,
    checkedCard
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      record: Object,
      form: Object,
      btnName: '',
      status: ''
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'fullname',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'تاريح الولادة',
          field: 'birthday',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'مكان الولادة',
          field: 'birthplace',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الجنسية',
          field: 'nationality',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'القيد',
          field: 'kayd',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الهاتف',
          field: 'phone',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الهاتف المحمول',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'العنوان',
          field: 'address',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'خبرات سابقة',
          field: 'previous_expertise',
          filter: 'agTextColumnFilter',
          valueGetter: previousExpertiseGetter
        },
        {
          headerName: 'المؤهلات والخبرات',
          field: 'qualifications_and_skills',
          filter: 'agTextColumnFilter',
          valueGetter: qualificationsAndSkillsGetter
        },
        {
          headerName: 'المواد التي تم تدريسها ',
          field: 'teaching_subjects',
          filter: 'agTextColumnFilter',
          valueGetter: teachingSubjectsGetter
        },
        {
          headerName: 'تاريخ الإنشاء',
          field: 'created_at',
          valueFormatter: self.dateFormatter,
          filter: 'agTextColumnFilter',
          sortable: false,
          width: 250
        },
        {
          headerName: 'تغيير حالة المقابلة',
          field: 'checked',
          valueGetter:
            function getObject(params) {
              return params;
            },
          filter: false,
          cellRendererFramework: AgSwitch,
          cellRendererParams: {
            switchAction: function (valueGetter) {
              self.checkedForm(valueGetter.id);
            }
          },
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            deleteRecord: function (id, rowIndex) {
              self.deleteForm(id, rowIndex);
            },
            actions: ['view', 'delete']
          },
        },
      ];
    },

    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.form = record;
    },
    deleteForm(ids, index, isMultiple = false) {

      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      formsRepo.deleteForm(ids).then((response) => {

        this.isLoading = false;
        if (response.code === 200) {
          if (isMultiple) {
            this.fetchAllForms();
          } else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    showDetails(index, action) {
      this.popupActive = true;
      this.record = utilities.fetchRecordIndex(this.rowData.length, index, action);
      this.form = this.rowData[this.record.index];
    },
    fetchAllForms(isChecked = false) {
      let formType = 'Training';
      this.btnName = `${isChecked ? 'لم تتم المقابلة' : 'تمت المقابلة'}`;
      this.status = isChecked;
      formsRepo.fetchAllFormsByType(formType, isChecked).then((data) => {
        this.rowData = data;
      });
    },
    checkedForm(ids, isMultiple = false) {
      formsRepo.checkedForm(ids, this.status, isMultiple, false).then((response) => {
        if (response.code === 200) {
          if (this.status)
            this.fetchAllForms(true);
          else
            this.fetchAllForms();
        }
      });
    },
    dateFormatter(params) {
      return utilities.dateFormatter(params.data.created_at);
    }
  },

  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllForms();
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
window.qualificationsAndSkillsGetter = function (params) {
  if (params.data.qualifications_and_skills) {
    return params.data.qualifications_and_skills.map(value =>
      `  الشهادة العلمية: ${value.scientific_certificate}, تخصص: ${value.specialization}, الجهة المانحة: ${value.awarding} `)
      .join(' / ');

  }
};
window.previousExpertiseGetter = function (params) {
  if (params.data.previous_expertise) {
    return params.data.previous_expertise.map(value =>
      `  الجهة المانحة: ${value.awarding}, المسمى الوظيفي:, ${value.position} `)
      .join(' / ');
  }
};
window.teachingSubjectsGetter = function (params) {
  if (params.data.teaching_subjects) {
    return params.data.teaching_subjects.map(value =>
      ` المادة: ${value.name}, عدد الساعات: ${value.hours}`)
      .join(' / ');

  }
};
</script>

<style scoped>
.borderBottom {
  border-bottom: 0.5px solid #5E445A;
}
</style>
